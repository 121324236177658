import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Layout from "../../components/common/layout/layout";
import PaymentSuccessModal from "../../components/Modals/PaymentSuccessModal";
import PaymentFailureModal from "../../components/Modals/PaymentFailureModal";

//importing images
import banner2 from "../../assets/images/Subscription/subcriptionBanner2.webp";
import icon1 from "../../assets/images/Subscription/subscriptionIconn1.webp";
import icon2 from "../../assets/images/Subscription/subscriptionIcon2.webp";
import icon3 from "../../assets/images/Subscription/subscriptionIcon3.webp";
import icon4 from "../../assets/images/Subscription/subscriptionIcon4.webp";
import icon5 from "../../assets/images/Subscription/subscriptionIcon5.webp";
import check from "../../assets/images/Subscription/CheckIcon.webp";

//importing styles
import "../../assets/scss/pages/subscription/subscription.scss";
import banner from "../../assets/images/Subscription/bannerSubscription.webp";
import Button from "../../components/common/button/Button";
import ReactPixel from 'react-facebook-pixel'
import { trackPageView } from "../../utils/googleAnalytics";

const SubscriptionPage = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const paymentFeatures = [
        {
            icon: icon1,
            name: "Fast & Convenience",
        },
        {
            icon: icon2,
            name: "Anytime, Anywhere",
        },
        {
            icon: icon3,
            name: "Affordable & Accessible",
        },
        {
            icon: icon4,
            name: "Variety Learning, Contents",
        },
        {
            icon: icon5,
            name: "Global Accredited, Certification"
        }
    ];

    const [paymentSuccessModal, setPaymentSuccessModal] = useState(false)
    const [paymentFailureModal, setPaymentFailureModal] = useState(false)

    useEffect(() => {
        if (searchParams?.get("status") === "success") {
            setPaymentSuccessModal(true)
        } else if (searchParams?.get("status") === "failure") {
            setPaymentFailureModal(true)
        }
        ReactPixel.track('subscription')
       
    }, [searchParams])

    useEffect(() => {
        document.title = "Reskills | Subscription"
        trackPageView('SubscriptionPage');
      }, []);

    if (searchParams?.get("status")) {
        return <>
            <Layout>
                <PaymentSuccessModal show={paymentSuccessModal} setShow={setPaymentSuccessModal} />
                <PaymentFailureModal show={paymentFailureModal} setShow={setPaymentFailureModal} />
            </Layout>
        </>
    } else {
        return (
            <>
                <Layout>
                    <div className="subscription-container">
                        <div className="d-md-flex align-items-center gap-5 mx-md-5 my-md-5 my-3 mx-3 ">
                            <div>
                                <img src={banner} alt="banner" className="w-100" />
                            </div>
                            <div>
                                <p className="fs-1 fw-bold mb-2 mt-md-0 mt-4">
                                    A POWERFUL NEW WAY OF <br />
                                    LEARNING
                                </p>
                                <p className="fs-5 mb-4 fw-bold">Learn Anytime, Anywhere</p>
                                <p className="fs-3 fw-bold text-secondary mb-2">Get your</p>
                                <span className="fs-1 fw-bold text-warning">PREMIUM</span>{" "}
                                <span className="fs-1 fw-bold"> NOW!</span>
                            </div>
                        </div>
                        <div className="py-5">
                            <img src={banner2} alt="banner" className="w-100" />
                            {/* <p className="fs-6 text-center text-white">Get started today</p>
            <p className="fs-4 text-center text-white fw-bold">
              ReSkills Premium Subscription
            </p> */}
                        </div>
                        <div>
                            <div className="card w-50 mx-auto ">
                                <div className="card-header bg-white d-md-flex align-items-start justify-content-between p-4">
                                    <div className="d-flex gap-2 align-items-center">
                                        <p className="m-0 fw-bold">Premium plan</p>{" "}
                                        <span className="fs-6 px-2 fw-semibold text-primary border rounded-pill sub-pill-border ">
                                            Popular
                                        </span>{" "}
                                    </div>
                                    <div className="d-flex gap-2 align-items-end">
                                        <p className="m-0 fs-1 fw-bold">$12</p>
                                        <span className="mb-2">Per year</span>
                                    </div>
                                </div>
                                <div>
                                    <h5 className="sub-m-y fw-bold mx-4">FEATURES</h5>
                                    <div className="mx-4 sub-container">
                                        <div className="">
                                            <div>
                                                <p className="text-size fw-bold my-2">Reskill OnLive</p>
                                                <div className="d-flex">
                                                    <img src={check} className="mx-2" alt="check" style={{width:'18px' , height:'18px'}} />
                                                    <p className="fs-5 mt-sub-2">Unlimited Daily LIVE Classes</p>
                                                </div>
                                                <div className="d-flex">
                                                    <img src={check} className="mx-2" alt="check" />
                                                    <p className="fs-5 mt-sub-2">Assessment On All Classes</p>
                                                </div><div className="d-flex">
                                                    <img src={check} className="mx-2" alt="check" />
                                                    <p className="fs-5 mt-sub-2">Pay Per Cert</p>
                                                </div>

                                            </div>
                                            <div>
                                                <p className="text-size fw-bold my-2">ReSkills OnTheGo</p>
                                                <div className="d-flex">
                                                    <img src={check} className="mx-2" alt="check" />
                                                    <p className="fs-5 mt-sub-2">Self Pace Videos Learning</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="text-size fw-bold my-2">Reskill Library</p>
                                                <div className="d-flex">
                                                    <img src={check} className="mx-2" alt="check" />
                                                    <p className="fs-5 mt-sub-2">E-Book</p>
                                                </div>
                                                <div className="d-flex">
                                                    <img src={check} className="mx-2" alt="check" />
                                                    <p className="fs-5 mt-sub-2">E-Magazine</p>
                                                </div><div className="d-flex">
                                                    <img src={check} className="mx-2" alt="check" />
                                                    <p className="fs-5 mt-sub-2">E-Note</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="ml-sub">
                                            <div>
                                                <p className="text-size fw-bold my-2">Special Learning</p>
                                                <div className="d-flex">
                                                    <img src={check} className="mx-2" alt="check" />
                                                    <p className="fs-5 mt-sub-2">1 minute Learning</p>
                                                </div>
                                                <div className="d-flex">
                                                    <img src={check} className="mx-2" alt="check" />
                                                    <p className="fs-5 mt-sub-2">Podcast Learning</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="text-size fw-bold my-2">Learning Profile</p>
                                                <div className="d-flex">
                                                    <img src={check} className="mx-2" alt="check" />
                                                    <p className="fs-5 mt-sub-2">Learners Profile</p>
                                                </div>
                                                <div className="d-flex">
                                                    <img src={check} className="mx-2" alt="check" />
                                                    <p className="fs-5 mt-sub-2">Assessment Report</p>
                                                </div>
                                                <div className="d-flex">
                                                    <img src={check} className="mx-2" alt="check" />
                                                    <p className="fs-5 mt-sub-2">Learning Reward</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="text-size fw-bold my-2">Support</p>
                                                <div className="d-flex">
                                                    <img src={check} className="mx-2" alt="check" />
                                                    <p className="fs-5 mt-sub-2">Dedicated Support</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="text-size fw-bold my-2">Others</p>
                                                <div className="d-flex">
                                                    <img src={check} className="mx-2" alt="check" />
                                                    <p className="fs-5 mt-sub-2">All Freemium Benefits</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="m-4" onClick={() => navigate("/subscription/payment")}>
                                    <Button name="Subscribe now" />
                                </div>
                            </div>
                        </div>
                        <div className="d-md-flex justify-content-between mx-md-5 my-md-5 my-3 mx-3">
                            {paymentFeatures.map((item) => {
                                return (
                                    <div className="text-center">
                                        <img src={item?.icon} alt="icon" style={{width:'100px'}} />
                                        <p className="fw-bold">{item?.name}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Layout>
            </>
        );
    }
};

export default SubscriptionPage;
