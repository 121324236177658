import { useState } from "react";
import { useNavigate } from "react-router-dom";
//importing components
import FeatureCard from "../../components/common/cards/FeatureCard/FeatureCard";

//importing style
import "../../assets/scss/pages/InterestSelection/InterestSelectionPage.scss";

//import images
import Icon1Active from "../../assets/images/InterestSelectionPage/Icon1Active.webp";
import Icon1Default from "../../assets/images/InterestSelectionPage/Icon1Default.webp";
import Icon2Active from "../../assets/images/InterestSelectionPage/Icon2Active.webp";
import Icon2Default from "../../assets/images/InterestSelectionPage/Icon2Default.webp";
import Icon3Active from "../../assets/images/InterestSelectionPage/Icon3Active.webp";
import Icon3Default from "../../assets/images/InterestSelectionPage/Icon3Default.webp";
import Icon4Active from "../../assets/images/InterestSelectionPage/Icon4Active.webp";
import Icon4Default from "../../assets/images/InterestSelectionPage/Icon4Default.webp";
import Icon5Active from "../../assets/images/InterestSelectionPage/Icon5Active.webp";
import Icon5Default from "../../assets/images/InterestSelectionPage/Icon5Default.webp";
import Icon6Active from "../../assets/images/InterestSelectionPage/Icon6Active.webp";
import Icon6Default from "../../assets/images/InterestSelectionPage/Icon6Default.webp";
import ButtonWithArrow from "../../components/common/button/ButtonWithArrow";

//importing services
import { updateUserProfile } from "../../services/user.service";

const InterestSelectionPage = () => {
  const navigate = useNavigate();
  const [professionName, setProfessionName] = useState();
  const [activeCard, setActiveCard] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const cardContents = [
    {
      id: 1,
      title: "I am still Studying ",
      activeImage: Icon4Active,
      defaultImage: Icon4Default,
    },
    {
      id: 2,
      title: "I am Employed",
      activeImage: Icon2Active,
      defaultImage: Icon2Default,
    },
    {
      id: 3,
      title: "I am a Freelance",
      activeImage: Icon5Active,
      defaultImage: Icon5Default,
    },
    {
      id: 4,
      title: "I am A Solopreneur",
      activeImage: Icon3Active,
      defaultImage: Icon3Default,
    },
    
    {
      id: 5,
      title: "I Employ People",
      activeImage: Icon1Active,
      defaultImage: Icon1Default,
    },
    
   
    
    
    {
      id: 6,
      title: "Others",
      activeImage: Icon6Active,
      defaultImage: Icon6Default,
    },
  ];

  /**
   * @function handleSubmit
   * @param {}
   * @description This function is used to call update user profile api
   */
  const handleSubmit = async (e) => {
    if(professionName){
      e.preventDefault();
    setIsLoading(true);
    const result = await updateUserProfile({
      profession: professionName,
    });

    if (result?.data?.status === "success") {
      navigate("/category-selection");
    }
    setIsLoading(false);
    }
    
  };
  return (
    <div className="custom-container max-w-home">
      <div
        className="InterestSelection-main-container py-5 px-5 "
        onClick={() => {
          setActiveCard("");
          setProfessionName("");
        }}
      >
        {/* {!showCategories && } */}
        <h3 className="fw-bolder text-center">My Job Status</h3>
        <p className="text-primary text-center mb-4">Select Your Preference</p>
        <div className="InterestSelection-card-container d-flex align-items-center gap-2">
          {cardContents?.map((item) => {
            return (
              <FeatureCard
                cardType="verticalWithHover"
                cardContent={item}
                setProfessionName={setProfessionName}
                setActiveCard={setActiveCard}
                activeCard={activeCard}
              />
            );
          })}
        </div>
        <div
          className="InterestSelection-btn-container cursor-pointer"
          onClick={(e) => handleSubmit(e)}
        >
          <ButtonWithArrow name="Next" isLoading={isLoading} disabled={!professionName}/>
        </div>
      </div>
    </div>
  );
};

export default InterestSelectionPage;
