import { useState } from "react";
import { useNavigate } from "react-router-dom";
//importing styles
import "../../assets/scss/components/forgetPassword/forgetPassword.scss";
import backIcon from "../../assets/images/Auth/BackIcon.svg";


//importing icon
import icon from "../../assets/images/Auth/ForgetPassIcon.webp";

//importing components
import Button from "../../components/common/button/Button";
import Input from "../../components/common/input/Input";

//importing sevices
import { forgetPassword } from "../../services/auth.service";


const ForgetPassword = () => {
  const [email, setEmail] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  /**
   * @function handleChange
   * @param {value}
   * @description This function is used to set email
   */
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  /**
   * @function handleVerify
   * @param {email}
   * @description This function is used to send OTP to user-phone for reset forget-password
   */

  const handleVerify = async () => {
    setIsLoading(true);
    const result = await forgetPassword({
      email: email,
    });
    if (result?.response?.data?.errors) {
      setError(true);
    }
    if (result?.data?.status === "success") {
      // setUserSession(tokens, user);
      navigate("/otp-verification", {
        state: {
          type: "forget_password",
          email: email,
          otp: result?.data?.user?.otp,
          verification_token: result?.data?.user?.verification_token,
        },
      });
    }
    setIsLoading(false);
  };
  return (
    <div className="forget-password-container w-25 mx-auto mt-5">
      <div className="text-center">
        <img src={icon}  style={{width:'70px' , height:'70px'}}alt="siteLogo" className="mb-4" />
        <h2 className="fw-bold fs-3 mb-2">Forgot Password?</h2>
        <p className="mb-4 text-secondary fs-6">
          Enter and submit the email address associated with your Reskills account. We will send a verification code to your email to reset your password.
        </p>
      </div>
      <div className="mb-2">
        <label className={`${error && "error-class"} fs-6`}>Email</label>
        <Input
          errorClass={error && "error-border"}
          placeholder="Enter your email"
          onChange={(e) => handleChange(e)}
        />
      </div>
      {error && (
        <p style={{ fontSize: "13px", color: "#f04438" }}>
           Sorry, this email is not associated to a registered account. 

        </p>
      )}
      {error && (
        <div>
          {/* <p className="fs-6 fw-bold my-2">Want to use this email address?</p> */}
          <p className="fs-6 text-secondary">
          To register for a new account, please click here to {" "}
            <span
              className="text-primary cursor-pointer"
              onClick={() => navigate("/sign-up")}
            >
              Sign up.
            </span>{" "}
            
          </p>
        </div>
      )}
      <div >
        <Button
          name={`${isLoading ? "Loading..." : "Get Verification Code"}`}
          disable={isLoading}
          onClick={() => handleVerify()}
        />
      </div>
      <div className="mt-3 text-center">
        
          <div
                className="justify-content-center d-flex align-item-center gap-2 cursor-pointer"
                onClick={() => navigate("/login")}
            >
                <img src={backIcon} style={{width:'15px' , height:'15px' , marginTop:'3px'}} alt="backIcon" />
                <p className="m-0 fs-6 fw-bold">Back to Log In</p>
            </div>
  
      </div>
    </div>
  );
};

export default ForgetPassword;
