import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useState } from "react";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";

import ratingIcon from "../../../../assets/images/Rating/ratingIcon.webp";
import dateIcon from "../../../../assets/images/Cards/HorizentalCard/dateIcon.webp";
import timerIcon from "../../../../assets/images/Cards/HorizentalCard/timerIcon.webp";
import wwwIcon from "../../../../assets/images/Navbar/wwwIcon.svg";
import avatar from "../../../../assets/images/Auth/avatar2.webp";

import Button from "../../button/Button";
import SkeletonBox from "../../../Skeleton/SkeletonBox";
import Image from "../../image";
import "../../../../assets/scss/components/horizentalCard/horizentalCard.scss";
import Skeleton from "react-loading-skeleton";
import SecRegisterModal from "../../../Modals/SecRegisterModal";
import { classRegistrationService } from "../../../../services/class.service";
import { selectLoggedIn } from "../../../../redux/userSlice";
import { useSelector } from "react-redux";

const HorizentalCard = ({ cardContent, setUpdate }) => {
  const date = new Date();
  const currenttTime = moment
    .parseZone(date)
    .local()
    .format()
    .replace(/-/g, "");

  const classEndDate = moment
    .parseZone(cardContent?.end_date_time)
    .local()
    .format()
    .replace(/-/g, "");

  const [loading, setLoading] = useState(false);
  const [secReg, setSecReg] = useState(false);
  const [secRegClass, setSecRegClass] = useState(null);
  const login = useSelector(selectLoggedIn);
  const navigate = useNavigate();

  const handleClassRegistration = async (event, classId) => {
    setLoading(true);

    try {
      const result = await classRegistrationService({ classId });

      if (result?.data?.class_registration?.registration_date) {
        toast.success(
          `You have successfully registered for the ${cardContent?.class_type?.name} course titled ${cardContent?.title}`
        );
        setUpdate("card");
      } else if (
        result?.response?.data?.error ===
        "You are already registered for a class during this time."
      ) {
        setSecReg(true);
        setSecRegClass(result?.response?.data);
      } else {
        toast.error(
          result?.response?.data?.error || "Class Registration has been closed."
        );
      }
    } catch (error) {
      toast.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  const parseHTMLString = (htmlString) => {
    // Create a DOMParser instance
    const parser = new DOMParser();

    // Parse the input string as HTML
    const doc = parser.parseFromString(htmlString, "text/html");

    // Check if there are any <p> tags in the parsed HTML
    const paragraphs = doc.querySelectorAll("p");

    // If there are <p> tags, extract their text content
    if (paragraphs.length > 0) {
      return Array.from(paragraphs).map((p) => p.textContent);
    }

    // If there are no <p> tags, return the input string as a single-element array
    return [htmlString];
  };

  return (
    <>
      <div className="text-dark text-decoration-none horizental-card-container">
        <div className="card shadow-sm border-0 card-high">
          <div className="row g-0">
            <div className="col-xl-5">
              {cardContent ? (
                <Link
                  to={`/course/${cardContent?.slug}`}
                  className="text-dark   text-decoration-none"
                >
                  <img
                    src={
                      cardContent?.images?.[0]?.image ??
                      cardContent?.thumb_image
                    }
                    className="card-img img-fluid rounded-start w-100 cursor-pointer"
                    alt="Class Thumbnail"
                  />
                </Link>
              ) : (
                <SkeletonBox>
                  <Skeleton height={275} />
                </SkeletonBox>
              )}
            </div>
            <div className="col-xl-7">
              <div className="card-body px-4 py-3">
                {cardContent?.class_type?.name ? (
                  <p
                    className="bg-primary-btn text-white px-3 fw-semibold inline-block"
                    style={{ width: "max-content", borderRadius: "10px" }}
                  >
                    {cardContent?.class_type?.name}
                  </p>
                ) : (
                  <Skeleton width={100} height={20} />
                )}

                <Link
                  to={`/course/${cardContent?.slug}`}
                  className="text-dark   text-decoration-none"
                >
                  <h5 className="truncate-2-lines flex-grow-1 fw-bold me-3 mb-2">
                    {cardContent?.title || <Skeleton height={25} />}
                  </h5>

                  <p className="card-text mb-4">
                    <small className="text-muted">
                      {parseHTMLString(cardContent?.short_description) || (
                        <Skeleton count={2} />
                      )}
                    </small>
                  </p>
                </Link>
                <Link
                  to={`/course/${cardContent?.slug}`}
                  className="text-dark   text-decoration-none"
                >
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center gap-1">
                      <img
                        src={dateIcon}
                        alt="Date Icon"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginTop: "2px",
                        }}
                      />
                      {cardContent?.start_date_time &&
                        moment(cardContent?.start_date_time).format(
                          "ddd, Do MMM YYYY"
                        )}
                    </div>
                    <div className="d-flex align-items-centre gap-1">
                      {cardContent?.start_date_time ? (
                        <>
                          <img
                            src={timerIcon}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginTop: "2px",
                            }}
                            alt="dateIcon"
                          />
                          {cardContent?.start_date_time &&
                            moment
                              .parseZone(cardContent?.start_date_time)
                              .local()
                              .format("hh:mm A")}{" "}
                        </>
                      ) : (
                        <SkeletonBox>
                          <Skeleton width={100} />
                        </SkeletonBox>
                      )}
                    </div>
                    <div className="d-flex align-items-center gap-1">
                      <img
                        src={wwwIcon}
                        alt="Language Icon"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginTop: "2px",
                        }}
                      />
                      {cardContent?.language?.name ?? "N/A"}
                    </div>
                  </div>
                </Link>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  {cardContent?.coaches?.map((item) => (
                    <Link
                      className="text-dark text-decoration-none"
                      to={`/coach/${item?.slug}`}
                    >
                      <div
                        className="d-flex gap-2 align-items-center"
                        key={item?.id}
                      >
                        <div className="rounded-circle overflow-hidden">
                          <Image
                            src={item?.avatar}
                            alt="Coach Avatar"
                            style={{ width: "45px", height: "45px" }}
                            defaultImage={avatar}
                          />
                        </div>
                        <div>
                          <p className="mb-0 fw-bold">
                            {item?.full_name ?? <Skeleton />}
                          </p>
                          {cardContent?.average_rating !== undefined && (
                            <Rating
                              initialValue={cardContent?.average_rating}
                              readonly
                              size={20}
                              allowFraction
                            />
                          )}
                        </div>
                      </div>
                    </Link>
                  ))}
                  {currenttTime < classEndDate && cardContent?.slug && (
                    <div className="mx-2-small">
                      <Button
                        name={
                          cardContent?.registrations?.[0]?.registration_date
                            ? "Registered"
                            : loading
                            ? "Loading..."
                            : "Register Now"
                        }
                        disable={loading}
                        btnType={
                          cardContent?.registrations?.[0]?.registration_date
                            ? "classEnded"
                            : ""
                        }
                        onClick={(e) =>
                          !login
                            ? navigate("/login")
                            : cardContent?.registrations?.[0]?.registration_date
                            ? ""
                            : handleClassRegistration(e, cardContent?.id)
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SecRegisterModal
        show={secReg}
        setShow={setSecReg}
        secRegClass={secRegClass}
        setUpdate={setUpdate}
        classType={cardContent?.class_type?.name}
      />
    </>
  );
};

export default HorizentalCard;
