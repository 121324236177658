import React, { useState, useEffect } from 'react';
import Button from '../../components/common/button/Button';
import SiteSlider from '../../components/common/slider/SiteSlider';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { getClassService } from '../../services/dashboard.service';

const UpComingClasses = () => {
    const { slug } = useParams();
    const [upComing, setUpComing] = useState([]);

    const date = moment(new Date()).format('YYYY-MM-DD');

    const fetchTodayLiveClasses = async () => {
        try {
            const result = await getClassService({
                filter: slug ? slug : '',
                up_coming_classes: true,
                date: slug === 'special' ? date : '', // Include `date` only if `slug` is not 'special'
                up_coming_date: slug === 'special' ? '' : date
            });
            setUpComing(result?.data?.classes?.data || []);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchTodayLiveClasses();
    }, [slug]);

    const getClassTitle = () => {
        switch (slug) {
            case 'onstage':
                return 'Upcoming OnStage';
            case 'onference':
                return 'Upcoming OnFerence';
            default:
                return 'Today’s LIVE sessions';
        }
    };

    return (
        <>
            {upComing?.length > 0 && (
                <div className="my-5 magin-x-5">
                    <h2 className="liveCourse-main-title fw-bold fs-4 mb-4 mx-2 my-4 margin-l">
                        {getClassTitle()}
                    </h2>
                    {upComing.length > 2 ? (
                        <div className="class-height">
                            <SiteSlider cardType="horizental" noOfItems={upComing?.length}>
                                {upComing?.map((item) => (
                                    <div className="position-relative py-3 mx-2 todays-classes" key={item.id}>
                                        <div>
                                            <img
                                                src={item?.images ? item.images[0]?.image : item?.thumb_image}
                                                className="w-100 border-radius"
                                                alt={`${item?.name}`}
                                            />
                                            <div className="position-absolute bottom-0 start-50 translate-middle mb-3">
                                                <Link
                                                    className="text-dark text-decoration-none"
                                                    to={`/course/${item?.slug}`}
                                                >
                                                    <Button name="Register Now" btnType="registerNow" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </SiteSlider>
                        </div>
                    ) : (
                        <div className="class-height">
                        <div className="d-flex justify-content-start align-items-center flex-wrap gap-4">
                            {upComing?.map((item) => (
                                <div
                                    className="position-relative py-3 mx-2 todays-classes"
                                    key={item?.id}
                                    style={{ maxWidth: '300px' }}
                                >
                                    <div>
                                        <img
                                            src={item?.images ? item.images[0]?.image : item?.thumb_image}
                                            className="w-100 border-radius"
                                            alt={`${item?.name}`}
                                        />
                                        <div className="position-absolute bottom-0 start-50 translate-middle mb-3">
                                            <Link
                                                className="text-dark text-decoration-none"
                                                to={`/course/${item?.slug}`}
                                            >
                                                <Button name="Register Now" btnType="registerNow" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default UpComingClasses;
