
import { Link, useNavigate } from "react-router-dom";

import { Modal } from "react-bootstrap";


//importing images
import siteLogo from "../../../assets/images/newSiteLogo.webp";






function TncNrmlModal({show , onHide}) {

   
    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header className="px-4 pb-0 border-0" closeButton>
                <Modal.Title>
                <Link to="/">
                            <img src={siteLogo}  alt="siteLogo" className="mb-4" />
                        </Link>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className='tran-container d-flex custom-container  justify-content-center' > 
            <div className='main-reward-container mx-4'>
                <p className='fs-4 fw-bold '>ReSkills 1-Year Premium Subscription - Terms and Conditions</p>
                <p className='my-4'>
                    Welcome to ReSkills! These Terms and Conditions (“Terms”) govern the 1-Year
                    Premium Subscription offered on the ReSkills platform. By subscribing to the
                    ReSkills 1-Year Premium Subscription (“Subscription”), you agree to abide by
                    these Terms.

                </p>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>1. Subscription Overview</p>
                    <p >1.1 The ReSkills 1-Year Premium Subscription offers access to a
comprehensive range of learning content, tools, and resources for 12
consecutive months.
                    </p>
                   
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>2. Eligibility</p>
                    <p >2.1 The Subscription is available to all individuals who have successfully
                    registered on the ReSkills platform.
                    </p>
                    <p className='my-4'>
                    2.2 Subscribers must provide accurate and up-to-date information during
registration.

                    </p>
                    
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>3. Subscription Fee and Payment</p>
                    <p >
                    3.1 The Subscription fee is $12, payable in full at the time of
subscription.

                    </p>
                    <p>
                    3.2 All payments are non-refundable, except as outlined under the Refund
                    Policy (Section 5).
                    </p>
                    <p>
                    3.3 ReSkills reserves the right to modify the subscription fee at any time,
                    with changes taking effect for new subscribers.
                    </p>
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>4. Subscription Period</p>
                    <p >
                    4.1 The Subscription is valid for 12 months from the date of successful
payment.

                    </p>
                    <p className='my-4'>
                    4.2 After the 12-month period, the Subscription will automatically expire
                    unless the user manually renews it.
                    </p>
                   
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>5. Refund Policy</p>
                    <p >
                    5.1 Once the Subscription has been purchased, no refunds will be issued
for any reason, including unused months or change of mind.
                    </p>
                    <p>
                    5.2 It is the subscriber's responsibility to ensure that they are committed
                    to using the platform before completing the purchase.
                    </p>
                </div>

                <div className='my-4'>
                    <p className='fs-5 fw-bold'>6. Access to Learning Material </p>
                    <p >
                    6.1 Subscribers will gain unlimited access to premium learning content,
live courses and other resources available on the platform for the duration of the
subscription.
                    </p>
                    <p className='my-4'>
                    6.2 ReSkills reserves the right to modify or discontinue certain content or
features at any time without notice.

                    </p>
                                    </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>7. Usage Limitations</p>
                    <p >
                    7.1 The Subscription is for individual use only. Sharing login credentials
                    or account access with others is strictly prohibited.
                    </p>
                    <p>
                    7.2 Any misuse or violation of the Terms may result in account
                    suspension or termination without refund.
                    </p>
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>8. Cancellation and Termination</p>
                    <p >
                    ancellation and Termination
8.1 Subscribers can cancel their subscription at any time through their
account settings. However, no refunds will be issued for unused months.

                    </p>
                    <p>
                    8.2 ReSkills reserves the right to terminate any subscription in case of
                    violations of these Terms or misuse of the platform.
                    </p>
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>9. Intellectual Property
                    </p>
                    <p >
                    9.1 All content provided through the Subscription remains the intellectual
                    property of ReSkills or its partners.
                    </p>
                    <p>
                    9.2 Subscribers are not permitted to copy, distribute, or otherwise exploit
                    content without express written permission from ReSkills
                    </p>
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>10. Changes to Terms</p>
                    <p >
                    10.1 ReSkills reserves the right to modify or update these Terms at any
time. Any changes will be communicated to subscribers via email or
notifications on the platform.
                    </p>
                    <p>
                    10.2 Continued use of the Subscription after changes have been
                    implemented signifies acceptance of the updated Terms.
                    </p>
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>11. Disclaimer</p>
                    <p >
                    11.1 ReSkills makes no guarantees regarding specific learning outcomes
                    or career advancements as a result of using the platform
                    </p>
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>12. Governing Law</p>
                    <p >
                    12.1 These Terms are governed by and construed in accordance with the
laws of Malaysia.

                    </p>
                </div>
                <div className='my-4'>
                    <p className='fs-5 fw-bold'>13. Contact Information</p>
                    <p >
                    13.1 For any questions or issues related to your Subscription, please
contact ReSkills customer service at support@reskills.com

                    </p>
               </div>
              <p className='my-4'>
              By subscribing to the ReSkills 1-Year Premium Subscription, you acknowledge
              that you have read, understood, and agreed to these Terms and Conditions
              </p>
            </div>
            
        </div>
            </Modal.Body>
            
        </Modal>
    );
}

export default TncNrmlModal;