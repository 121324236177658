import React from 'react';
import Certificate from '../../../assets/images/NoData/certificate.webp'
import CourceHistory from '../../../assets/images/NoData/person-learning-online 1.webp'
import CoachReview from '../../../assets/images/NoData/Frame.webp'
import MyLearning from '../../../assets/images/NoData/my-learning.webp'
import Search from '../../../assets/images/NoData/search.webp'
import Language from '../../../assets/images/NoData/lang.webp'


const NoData = ({ page, slug }) => {
    const getClassTitle = () => {
        switch (slug) {
            case 'onlive':
                return 'Upcoming OnLIVE'
            case 'onstage':
                return 'OnStage'
            case 'onference':
                return 'OnFerence'
            case 'onthego':
                return 'OnTheGo'
            case 'special':
                return 'Special'
            default:
                return 'Class'
        }
    }
    return (
        <div>
            {page === "not-found" ?
                <div className='d-flex justify-content-center align-items-center flex-column py-4'>
                    <img className='img-fluid' src={Search} style={{width:'400px'}} alt='no-data'/>
                    <h4 className='fw-bold'>Result Not Found</h4>
                    <p className='text-center'>The link you pasted did not return any results.
                        <br /> Please check the link and try again.</p>
                </div> : page === "user-certificate" ?
                    <div className='d-flex justify-content-center align-items-center flex-column py-4'>
                        <img className='img-fluid' src={Certificate} style={{width:'400px'}} alt='no-data' />
                        <h4 className='fw-bold'>No certificates Yet</h4>
                        <p className='text-center'>You haven't received any certificates yet. Complete courses and achievements<br /> to earn your first certificate!</p>
                    </div> :
                    page === "user-certificate-verify" ?
                        <div className='d-flex justify-content-center align-items-center flex-column py-4'>
                            <img className='img-fluid' src={Certificate} style={{width:'400px'}} alt='no-data' />
                            <h4 className='fw-bold'>Invalid Certificate Serial Number</h4>
                            <p className='text-center'>The serial number you entered was not found.

                                <br /> Please check and try again.</p>
                        </div>
                        :
                        page === "coach-Cources-History" ?
                            <div className='d-flex justify-content-center align-items-center flex-column py-4'>
                                <img className='img-fluid' src={CourceHistory} style={{width:'400px'}} alt='no-data' />
                                <h4 className='fw-bold'>No Courses Created</h4>
                                <p className='text-center'>Coach haven't created any courses yet.</p>
                            </div> :
                            page === "coach-review" ?
                                <div className='d-flex justify-content-center align-items-center flex-column py-4'>
                                    <img className='img-fluid' src={CoachReview} style={{width:'400px'}} alt='no-data' />
                                    <h4 className='fw-bold'>No Ratings or Reviews</h4>
                                    <p className='text-center'>This coach hasn't received any ratings or reviews yet. Check back later to see<br /> what others have to say!</p>
                                </div> :
                                page === "my-learning" ?
                                    <div className='d-flex justify-content-center align-items-center flex-column py-4'>
                                        <img className='img-fluid' src={MyLearning} style={{width:'400px'}} alt='no-data' />
                                        <h4 className='fw-bold'>No Courses Yet</h4>
                                        <p className='text-center'>You haven't joined any courses. Start learning by exploring our available courses <br /> and enhance your skills today!</p>
                                    </div> :
                                    page === "class-pagination" ?
                                        <div className='d-flex justify-content-center align-items-center flex-column py-4'>
                                            <img className='img-fluid' src={Language} style={{width:'400px'}} alt='no-data' />
                                            <h4 className='fw-bold'>No {getClassTitle()}</h4>
                                            <p className='text-center'>Currently, there are no {getClassTitle()} classes. Stay tuned for updates!</p>
                                        </div> :
                                     page === "no-coach-found" ?
                                     <div className='d-flex justify-content-center align-items-center flex-column py-4'>
                                         <img className='img-fluid' src={CoachReview} style={{width:'400px'}} alt='no-data' />
                                         <h4 className='fw-bold'>No Coach Found</h4>
                                     </div> :
                                        ''
            }
        </div>
    );
};

export default NoData;