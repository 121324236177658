import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { setCoach, selectCoachDetail } from "../../../redux/coachSlice";
import FeaturedCoachCard from "../cards/FeaturedCoachCard/FeaturedCoachCard";
import { getFeaturedCoaches } from "../../../services/coach.service";
import "../../../assets/scss/components/paginate/paginate.scss";
import NoData from "../noData/NoData";

function Items({ currentItems }) {
  if (!Array.isArray(currentItems) || currentItems.length === 0) {
    return <NoData page="no-coach-found" />;
  }

  return (
    <>
      {currentItems.map((item, index) => (
        <div key={index}>
          <FeaturedCoachCard cardDetail={item} />
        </div>
      ))}
    </>
  );
}

const CoachPaginate = ({ searchClass }) => {
  const dispatch = useDispatch();
  const Classes = useSelector(selectCoachDetail) || []; // Ensure it's always an array

  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const itemsPerPage = 8;

  useEffect(() => {
    const fetchCoaches = async () => {
      try {
        const result = await getFeaturedCoaches({
          perPageItem: itemsPerPage,
          page: currentPage + 1,
          status: true,
          searchClass: searchClass,
        });

        const fetchedCoaches = result?.data?.coaches?.data || [];
        setTotalItem(result?.data?.coaches?.total || 0);
        dispatch(setCoach(fetchedCoaches));
      } catch (error) {
        console.error("Error fetching coaches:", error);
      }
    };

    fetchCoaches();
  }, [currentPage, searchClass, dispatch]);

  const pageCount = Math.ceil(totalItem / itemsPerPage);

  const handlePageClick = async (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
    setCurrentPage(event.selected);
  };

  return (
    <>
      <div className="d-flex flex-wrap justify-content-center">
        <Items currentItems={Classes} />
      </div>
      {pageCount > 1 && (
        <div className="d-flex justify-content-center">
          <ReactPaginate
            breakLabel="..."
            nextLabel={currentPage === pageCount - 1 ? null : "Next"}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={currentPage === 0 ? null : "Previous"}
            renderOnZeroPageCount={null}
            className="paginationComponent"
            containerClassName="pagination"
            previousClassName={currentPage === 0 ? "previous hide-arrow" : "previous"}
            nextClassName={currentPage === pageCount - 1 ? "hide-next-arrow" : "next"}
          />
        </div>
      )}
    </>
  );
};

export default CoachPaginate;
