import React from 'react';
import Rating from 'react-rating';
import Image from '../common/image';
import BgImg from "../../assets/images/CoachReview/bg.webp";
import AvatarImg from "../../assets/images/CoachReview/Avatar.webp";
import ratingIcon from "../../assets/images/Rating/ratingIcon.webp";
import ReviewPaginate from '../common/paginate/ReviewPaginate';
import Navbar from '../common/navbar/Navbar';
import Footer from '../common/footer/Footer';
import { useParams } from 'react-router-dom';



function ViewAllReview(props) {
    const { slug } = useParams();
    

    return (
        <div>
            <Navbar/>
            <div class="card  max-my-card my-3">
            <div className='mx-5-w'>
                <h2 className=" fw-bold fs-4 mb-4 mx-3 d-flex">  
                    <div className="mx-1 my-4">
                        Rates & Reviews
                    </div>
                </h2>
                <ReviewPaginate id={slug}/>
            </div>
            
            
            </div>
        <Footer/>

        </div>
        
    );
}

export default ViewAllReview;