import { useEffect ,useState } from "react";

import CoverImg from "../../assets/images/Subscription/cover.webp"
import check from "../../assets/images/Subscription/CheckIcon.webp";
import { IoMdStar } from "react-icons/io";

//importing styles
import "../../assets/scss/components/subscriptionDetail/subscriptionDetail.scss"

import Button from "../../components/common/button/Button";
import SummaryModal from "../../components/Modals/RateAndReviewModals/SummaryModal";
import Navbar from "../../components/common/navbar/Navbar";
import Footer from "../../components/common/footer/Footer";
import icon1 from "../../assets/images/Subscription/subscriptionIconn1.webp";
import icon2 from "../../assets/images/Subscription/subscriptionIcon2.webp";
import icon3 from "../../assets/images/Subscription/subscriptionIcon3.webp";
import icon4 from "../../assets/images/Subscription/subscriptionIcon4.webp";
import icon5 from "../../assets/images/Subscription/subscriptionIcon5.webp";
import GuestSignUpModal from "../../components/Modals/Guest-subscripltion/GuestSignUpModal";
import GuestSignInModal from "../../components/Modals/Guest-subscripltion/GuestSignInModal";
import GuestOtp from "../../components/Modals/Guest-subscripltion/GuestOtp";
import GuestUpdateEmailModal from "../../components/Modals/Guest-subscripltion/GuestUpdateEmail";
import TncLnPModal from "../../components/Modals/Guest-subscripltion/TncLnPModal";
import SummaryModalGuest from "../../components/Modals/Guest-subscripltion/SummaryModalGuest";
import TncNrmlModal from "../../components/Modals/Guest-subscripltion/TncNrmlModal";
import { trackPageView } from "../../utils/googleAnalytics";



const GuestSubPage = () => {
  
    const [show, setShow] = useState(false)
    const [showSignIn, setShowSingIn] = useState(false)
    const [showOtp, setShowOtp] = useState(false)
    const [subscriptionType , setSubscriptionType] = useState('normal')
    const [selected , setSelected] = useState('LEP')
    const [userEmail ,setUserEmail ]=useState()
    const [accessToken , setAccessToken] = useState()
    const [showEmail, setShowEmail] = useState(false)
    const [showSummaryModal , setShowSummaryModal] =useState(false)
    const [from , setFrom]=useState('')
    const [TnC , setTnC] = useState('')
    const [newAccessToken , setNewAccessToken] = useState()
    const [cameFromUpdateEmail ,setCameFromUpdateEmail ] = useState(false)
    const [remainResendAttempt, setRemainResendAttempt] = useState();
    
    const [seconds, setSeconds] = useState(0);


    


    const paymentFeatures = [
        {
            icon: icon1,
            name: "Fast & Convenience",
        },
        {
            icon: icon2,
            name: "Anytime, Anywhere",
        },
        {
            icon: icon3,
            name: <>
                Affordable & <br />
                Accessible
            </>
        },
        {
            icon: icon4,
            name: <>Variety Learning,<br/> Contents</>,
        },
        {
            icon: icon5,
            name: <>Global Accredited,<br/> Certification</>
        }
    ];

  

    const onNormal=()=>{
        setShow(true)
        setSubscriptionType('normal')
    }

    const onLEP=()=>{
        setShow(true)
        setSubscriptionType('LEP')
    }

    const onHide = () => {
        setShow(!show)
        setShowSingIn(false)
    }

    const onSignIn =()=>{
        setShowSingIn(true)
        setShow(false)
    }

    const onSignUp =()=>{
        setShowSingIn(false)
        setShow(true)
    }
    const onHideSignUp =()=>{
        setShowSingIn(false)
    }
    const onHideOtp =()=>{
        setShowOtp(false)
    }

    const onShowOtp =()=>{
        setShowOtp(true)
        setShowSingIn(false)
        setShow(false)
        setShowEmail(false)
    }

    const onHideEmail =()=>{
        onShowOtp()
    }

    const updateShowEmail=()=>{
        setShowEmail(true)
        setShowOtp(false)
    }

    const HideSummaryModal =()=>{
        setShowSummaryModal(false)
    }

    const HideTnC =()=>{
        setTnC('')
    }

     useEffect(() => {
            document.title = "Reskills | Guest Subscription"
            trackPageView('GuestSubscriptionPage');
        }, []);


    return (
        <div>
            <Navbar/>
       
            <div className={`w-100 ${window.innerWidth < 768 ? '' : 'subscription-container'}`}>

                <div className="w-100 ">
                    <img src={CoverImg} alt='cover' className="w-100" />
                </div>
                <div className="subscriber-container gap-5 w-100 my-5">
                    <div className={`shadow-sm rounded my-4  subscriber-card   bg-card-slati `} onClick={()=>{setSelected('normal')}}>
                        <h3 className="fw-bold text-end text-primary p-2">$12</h3>
                        <div className="p-3 px-5 ">
                            <h4 className="fw-bold text-center">1-Year</h4>
                            <h5 className="fw-semibold text-center"> Premium Subscription</h5>
                            <p className="text-center text-white fw-semibold mx-auto" style={{ background: "#7B7B7B", width: "110px" }}>Value Pack</p>
                            <div>
                                <Button
                                    name={"Subscribe Now"}
                                    onClick={onNormal}
                                />
                            </div>
                            <hr style={{ color: "#98a2b3" }}  className="my-3"/>
                            <p className="fw-bold text-start">FEATURES</p>

                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills OnLive</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Unlimited Daily LIVE Courses</h6>
                                </div>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Course Assessment</h6>
                                </div> <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Accredited Certificates</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills OnTheGo</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Self-Paced Video Learning</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills OnStage</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Industry Leaders' Talks & Sharing</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills OnFerence</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Online Learning Conference</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills On-1</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Short and Focused Learning Contents</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills Special</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Tailored Learning Campaigns<br/> and Activities</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">Learner's Profile</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Learning Dashboard</h6>
                                </div>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Assessment Report</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">Support</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Full Dedicated Support</h6>
                                </div>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Community of Like-Minded Learners</h6>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                    <div className={`shadow-sm rounded my-4  subscriber-card   bg-card-slati `} onClick={()=>{setSelected('LEP')}}>
                        <h3 className="fw-bold text-end text-primary p-2">$24</h3>
                        <div className="p-3 px-5 ">
                            <h4 className="fw-bold text-center">2-Year</h4>
                            <h5 className="fw-semibold text-center"> Premium Subscription</h5>
                            <p className="text-center text-white fw-semibold mx-auto" style={{ background: "#B74073", width: "140px" }}>Most Popular</p>

                            <div>
                                <Button
                                    name={"Subscribe Now"}
                                    onClick={onLEP}
                                />
                            </div>
                            <hr style={{ color: "#98a2b3" }}  className="my-3"/>
                            <div className="d-flex ">
                                <IoMdStar style={{color:"#FFD700"}} size={24} />
                                <h5 className="fw-bold text-start">EXTRA BENEFITS</h5>
                                <IoMdStar style={{color:"#FFD700"}} size={24} />
                            </div>

                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">Learning Point System</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Accumulate Points</h6>
                                </div>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Reward Selection</h6>
                                </div> 
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Added Learning Experience</h6>
                                </div>
                                 
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Expansive Network and Community</h6>
                                </div>
                            </div>
                            <hr style={{ color: "#98a2b3" }}  className="my-3"/>

                            <p className="fw-bold text-start">FEATURES</p>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills OnLive</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Unlimited Daily LIVE Courses</h6>
                                </div>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Course Assessment</h6>
                                </div> <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Accredited Certificates</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills OnTheGo</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Self-Paced Video Learning</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills OnStage</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Industry Leaders' Talks & Sharing</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills OnFerence</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Online Learning Conference</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills On-1</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Short and Focused Learning Contents</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills Special</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Tailored Learning Campaigns<br/> and Activities</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">Learner's Profile</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Learning Dashboard</h6>
                                </div>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Assessment Report</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">Support</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Full Dedicated Support</h6>
                                </div>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check"  style={{width:"25px" , height:'25px' , marginTop:'6px'}} />
                                    <h6 className="my-2 ">Community of Like-Minded Learners</h6>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                </div>
                <div className="d-md-flex justify-content-between mx-md-5 my-md-5 my-3 mx-3">
                            {paymentFeatures.map((item) => {
                                return (
                                    <div className="text-center">
                                        <img src={item?.icon} alt="icon" style={{width:'100px'}} />
                                        <p className="fw-bold">{item?.name}</p>
                                    </div>
                                );
                            })}
                        </div> 
                <GuestOtp 
                    showMe={showOtp}
                    onHideMe={onHideOtp}
                    onSignIn={onSignIn}
                    selected={selected}
                    userEmail={userEmail}
                    verification_token={accessToken}
                    setShowEmail={updateShowEmail}
                    setShowSummaryModal={setShowSummaryModal}
                    from={from}
                    newAccessToken={newAccessToken}
                    setAccessToken={setAccessToken}
                    setRemainResendAttempt={setRemainResendAttempt}
                    remainResendAttempt={remainResendAttempt}
                    setSeconds={setSeconds}
                    seconds={seconds}
                    cameFromUpdateEmail={cameFromUpdateEmail}
                    setCameFromUpdateEmail={setCameFromUpdateEmail}
                />

                <GuestUpdateEmailModal
                    show={showEmail}
                    onHide={onHideEmail}
                    verification_token={accessToken}
                    setUserEmail={setUserEmail}
                    setFrom={setFrom}
                    onShowOtp={onShowOtp}
                    setCameFromUpdateEmail={setCameFromUpdateEmail}
                    setRemainResendAttempt={setRemainResendAttempt}
                    setSeconds={setSeconds}

                    
                />

                <GuestSignInModal 
                    show={showSignIn}
                    onHide={onHideSignUp}
                    onSignUp={onSignUp}
                    selected={selected}
                    setAccessToken={setAccessToken}
                    onShowOtp={onShowOtp}
                    setShowSummaryModal={setShowSummaryModal}

                />

                <GuestSignUpModal
                    show={show}
                    onHide={onHide}
                    onSignIn={onSignIn}
                    selected={selected}
                    setShowOtp={onShowOtp}
                    setUserEmail={setUserEmail}
                    setAccessToken={setAccessToken}
                    setNewAccessToken ={setNewAccessToken}
                />

                <SummaryModalGuest
                    show={showSummaryModal}
                    onHide={HideSummaryModal}
                    subscriptionType={selected}
                    setTnC={setTnC}
                    
                />

                <TncLnPModal
                    show={TnC === 'LEP'}
                    onHide={HideTnC}
                />
                <TncNrmlModal
                    show={TnC === 'normal'}
                    onHide={HideTnC}
                />  
            </div>
            <Footer/>
            

        </div>
      
    );
};

export default GuestSubPage;