// importing styles
import "../../../assets/scss/components/dropdown/dropdown.scss";

const Dropdown = ({ options, setSelectedCountry, defaultOption ,setSelectedCountryId }) => {
  /**
   * @function dropdownChangeHandler
   * @param value {string} The concatenated value from the dropdown
   * @description This function is used to extract the selected value and set it to the state
   */
  const dropdownChangeHandler = (value) => {
    const [id, countryId] = value.split(","); // Split the concatenated value
    setSelectedCountry(id); // Set only the id
    setSelectedCountryId(countryId); // Set the country_id
  };

  return (
    <div className="dropdown-main-container">
      <select
        className="dropdown-itemMenu form-control"
        onChange={(e) => dropdownChangeHandler(e.target.value)}
      >
        <option className="dropdown-items" disabled selected>
          {defaultOption||'Select a country'}
        </option>

        {options?.map((item, index) => {
          return (
            <option
              key={index}
              className="dropdown-items"
              value={`${item?.id},${item?.isd_code}`} // Concatenate id and country_id
              selected={item?.id == defaultOption}
            >
              {item.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Dropdown;
