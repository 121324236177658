import Account from '../account/Account';

// Image Import
import Smallicon from '../../assets/images/LearnAndEarn/reskillroundimage.webp'
import arrow from '../../assets/images/LearnAndEarn/left-arrow.webp'

// Import Css
import '../../assets/scss/pages/learnAndEarn/learnAndEarn.scss'
import Button from '../../components/common/button/Button';

const RewardMission = () => {
    return (
        <Account>
            <div className='tran-container d-flex  justify-content-center'>
                <div className='main-tr-container '>
                    <h4 className='main-title fw-bold py-3'><img src={arrow} alt='arrow' className=' mb-1 mr-arrow-2' />Referral Rewards</h4>
                    <h6>Earn 2.4pts ($2.4) for referring any friends to join in ReSkills Learn & Earn Program.</h6>
                    <small className='text-muted'>(No max rewards cap for each account on referral rewards)</small>
                    <div className='shadow-sm rounded my-4 border border-light  flex-column p-3 bg-card-primary'>

                        <div className='mx-2 d-flex justify-content-between'>
                            <div className='fw-semibold mx-2 text-white'>Total Earned Referral Reward Points (1 point = $1)</div>
                            <img src={Smallicon} style={{ height: "30px", width: "30px" }} alt='logo' />
                        </div>
                        <div className='fs-4 fw-bold text-white mx-3'>2350</div>
                    </div>


                    <div className='shadow-sm rounded my-3 border border-light  p-3 d-flex'>
                        <div className='fw-semibold'>Reward</div>
                        <div className='fw-semibold text-primary mx-5 border-bottom'>Challenge</div>
                    </div>

                    <div className='shadow-sm rounded my-3 border border-light  p-2 '>
                        <div className='fw-semibold mb-3'>Share 3 Learning on Facebook</div>
                        <div className=' container d-flex justify-content-between align-items-center'>
                            <div className="progress w-100 ">
                                <div className="progress-bar bg-card-primary" role="progressbar" style={{ width: "25%" }} aria-valuenow={25} aria-valuemin="0" aria-valuemax={100}></div>
                            </div>
                            <span className='fw-semibold mx-2 '>2/3</span>
                            <div className='fix-width'>
                                <Button
                                    name='Complete Now'
                                />
                            </div>
                        </div>
                        <small className='text-success fw-semibold'>+2,000 Ranking XP & 50 Reward Points</small>
                    </div>
                    <div className='shadow-sm rounded my-3 border border-light  p-2 '>
                        <div className='fw-semibold mb-3'>Share 3 Learning on Facebook</div>
                        <div className=' container d-flex justify-content-between align-items-center'>
                            <div className="progress w-100 ">
                                <div className="progress-bar bg-card-primary" role="progressbar" style={{ width: "25%" }} aria-valuenow={25} aria-valuemin="0" aria-valuemax={100}></div>
                            </div>
                            <span className='fw-semibold mx-2 '>2/3</span>
                            <div className='fix-width'>
                                <Button
                                    name='Complete Now'
                                />
                            </div>
                        </div>
                        <small className='text-success fw-semibold'>+2,000 Ranking XP & 50 Reward Points</small>
                    </div><div className='shadow-sm rounded my-3 border border-light  p-2 '>
                        <div className='fw-semibold mb-3'>Share 3 Learning on Facebook</div>
                        <div className=' container d-flex justify-content-between align-items-center'>
                            <div className="progress w-100 ">
                                <div className="progress-bar bg-card-primary" role="progressbar" style={{ width: "25%" }} aria-valuenow={25} aria-valuemin="0" aria-valuemax={100}></div>
                            </div>
                            <span className='fw-semibold mx-2 '>2/3</span>
                            <div className='fix-width'>
                                <Button
                                    name='Redeem'
                                    btnType='redeem'
                                />
                            </div>
                        </div>
                        <small className='text-success fw-semibold'>+2,000 Ranking XP & 50 Reward Points</small>
                    </div>


                </div>
            </div>
        </Account>
    );
};

export default RewardMission;