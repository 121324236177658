import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CategoryCard from "../../components/common/cards/categoryCard/CategoryCard";
import ButtonWithArrow from "../../components/common/button/ButtonWithArrow";

//importing style
import "../../assets/scss/pages/categorySelection/categorySelectionPage.scss";

//importing services
import { getFeaturedCategories } from "../../services/category.service";
import { updateUserProfile } from "../../services/user.service";
import { toast } from "react-toastify";

const CategorySelectionPage = () => {
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const redirectUrl = sessionStorage.getItem("redirectAfterLogin");


  
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const result = await getFeaturedCategories({
          highlight:false
        });
        setCategoryList(result?.data?.categories);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCategory();
  }, []);
  

  /**
   * @function handleSubmit
   * @param {}
   * @description This function is used to call update user profile api
   */
  let selectedCategories = [];

  const handleSubmit = async (e) => {
    if(selectedCategories?.length !==0){
        e.preventDefault();
        setIsLoading(true);
        const result = await updateUserProfile({
          category_ids: selectedCategories,
        });
        if (result?.data?.status === "success") {
           // Clear session storage after reading
           sessionStorage.removeItem("redirectAfterLogin");
           if(redirectUrl){
               navigate(redirectUrl)
           }else{
               navigate("/");
           }
  
        }
        setIsLoading(false);
    }else{
        toast.error("Please Select Atleast one Category.")
    }
    
  };

  
  return (
    <div className="custom-container max-w-home">
      <div className="categorySelection-main-container py-5 px-5">
        <h3 className="fw-bolder text-center">What You Wish To Learn</h3>
        <p className="text-primary text-center mb-5">
          Pick a category you are interested in
        </p>
        <div>
          <div className="categorySelection-inner-container row w-100">
            {categoryList?.map((item, index) => {
              return (
                <div className="col-4" key={index}>
                  <CategoryCard

                    cardDetail={item}
                    setSelectedCategory={setSelectedCategory}
                    selectedCategories={selectedCategories}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="categorySelection-btn-container"
          onClick={(e) => handleSubmit(e)}
        >
          <ButtonWithArrow name="Next" isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default CategorySelectionPage;
