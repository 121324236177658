import React from 'react';
import PageNotFoundImg from '../../assets/images/404/404.webp'
import Navbar from '../../components/common/navbar/Navbar';
import Footer from '../../components/common/footer/Footer';
const PageNotFound = () => {
    return (
        <div className=''>
            <Navbar/>
            <div className='d-flex justify-content-center align-items-center flex-column py-4'>
                <img src={PageNotFoundImg}/>
                <h2 className='fw-bold'>Search not found</h2>
            </div>
            <Footer/>
        </div>
    );
};

export default PageNotFound;