import { useState ,useEffect } from "react";
import OtpInput from 'react-otp-input';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

//importing components
import Button from "../../../components/common/button/Button";
import UpdateEmailModal from "../../../components/Modals/UpdateEmailModal";

//importing styles
import "../../../assets/scss/pages/auth/otp/otpPage.scss";

//importing images
import icon from "../../../assets/images/Auth/OtpIcon.webp";
import backIcon from "../../../assets/images/Auth/BackIcon.svg";
import { setUserSession } from "../../../utils/Auth.Service";
import { OTPValidation, resendOtp, VerifyOtpUpdateEmail } from "../../../services/auth.service";
import { setToken } from "../../../redux/userSlice";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import GuestUpdateEmailModal from "./GuestUpdateEmail";
import Cookies from "js-cookie";



function GuestOtp({showMe , onHideMe ,selected , userEmail ,verification_token , setShowEmail ,setShowSummaryModal ,from ,newAccessToken , setAccessToken , remainResendAttempt ,setRemainResendAttempt ,setSeconds ,seconds ,cameFromUpdateEmail ,setCameFromUpdateEmail}) {

    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { control, watch, formState: { errors } } = useForm({ mode: "onBlur" });
    const watchOtp = watch("otp")

    const [isLoading, setIsLoading] = useState({
        verifyEmail: false,
        ClicktoResend: false,
    });
    const [wrongOtp, setWrongOtp] = useState(false);
    const [expiredOtp, setExpiredOtp] = useState(false);
    const [resendOtpError, setResendOtpError] = useState();
    // const [remainResendAttempt, setRemainResendAttempt] = useState();
    const [show, setShow] = useState(false)
    // const [seconds, setSeconds] = useState(0);

   const initialTime = 120;
   const [timeLeft, setTimeLeft] = useState(initialTime);


   useEffect(() => {
    if (!showMe) return; // Start timer only when modal opens

    setTimeLeft(initialTime); // Reset timer when modal opens

    let timer = setInterval(() => {
        setTimeLeft((prevTime) => {
            if (prevTime <= 1) {
                clearInterval(timer);
                return 0;
            }
            return prevTime - 1;
        });
    }, 1000);

    return () => clearInterval(timer); // Cleanup on unmount

}, [showMe]);

// const formatTime = (seconds) => {
//     const minutes = Math.floor(seconds / 60);
//     const secs = seconds % 60;
//     return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
// };


    const onHide = () => {
        setShow(!show)
    }


    /**
        * @function handleSubmit
        * @param (e)
        * @description This function is used to verify otp
    */



    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading({ verifyEmail: true });

        let result
        if (from === 'verify-otp') {
            result = await VerifyOtpUpdateEmail({
                email: userEmail,
                otp: watchOtp,
                verification_token: verification_token
            });
        } else {
            result = await OTPValidation({
                email: userEmail,
                otp_type: 'register',
                otp: watchOtp,
            })
        }


        if (result?.data?.message && result?.data?.status === "failed") {
            toast.error(result?.data?.message || "Something went wrong")
        } else if (result?.response?.data?.message) {
            toast.error(result?.response?.data?.message || "Something went wrong")
        }
        if (result?.data?.status === "success") {
           
                const tokens = {
                    tokenAccess: result?.data?.access_token,
                };
                const user = result?.data?.user;
                dispatch(setToken(tokens?.tokenAccess))
                setUserSession(tokens, user);
                setShowSummaryModal(true)
                onHideMe()
                setCameFromUpdateEmail(false)
                setTimeLeft(120)
            }
         else if (result?.data?.message === "Invalid OTP") {
            setWrongOtp(true);
            setExpiredOtp(false);
        } else if (result?.data?.message === "OTP has expired") {
            setExpiredOtp(true);
        }
        setIsLoading({ verifyEmail: false });
    };

    /**
        * @function handleResendOtp
        * @param 
        * @description This function is used to resend password
    */
    const handleResendOtp = async () => {
        setIsLoading({ ClicktoResend: true });
        const result = await resendOtp({
            email: userEmail,
            otp: state?.otp,
        });
        if (result?.response?.data?.message) {
            setResendOtpError(result?.response?.data?.message);
        }
        if (result?.data?.status === "success") {
            setRemainResendAttempt(3 - result?.data?.user?.otp_resend_count);
            setAccessToken(result?.data?.user?.verification_token)
            setCameFromUpdateEmail(false)
            setSeconds(60)
        }
        setIsLoading({ ClicktoResend: false });
        setExpiredOtp(false);

    };

    const handleVerifyLater =()=>{
       setShowSummaryModal(true)
       onHideMe()
       sessionStorage.setItem('verify_later', true);
       Cookies.set("tokenAccess", newAccessToken);
    }

    

    useEffect(() => {
        if (seconds === 0 || remainResendAttempt === 0) return;
    
        const timer = setInterval(() => {
            setSeconds((prev) => prev - 1);
        }, 1000);
    
        return () => clearInterval(timer);
    }, [seconds, remainResendAttempt]);
    


    return (
        <Modal show={showMe} onHide={onHideMe} backdrop="static" keyboard={false}>
            <Modal.Header className="px-4 pb-0 border-0" closeButton>
                
               
            </Modal.Header>
            <Modal.Body>
            <div className="mx-4">
            <div className="text-center">
                <img src={icon} style={{width:'70px' , height:'70px'}} alt="icon" className="mb-4" />
                <h2 className="fw-bold fs-4 mb-2">Check your email</h2>
                {/* <p className="text-secondary">Congratulations! Your ReSkills account has been successfully created. Final step: Verify your email. </p> */}
                <p className="text-secondary my-3"> 
                    Please enter the OTP verification code that we sent to {userEmail} below. If you don’t find it in your inbox, please check your spam folder. 
                </p>

            </div>
            <Controller
                control={control}
                name={`otp`}
                render={({ field }) => (
                    <OtpInput
                        containerStyle="verifiction-input-div justify-content-between mb-3"
                        inputStyle="verifiction-input form-control rounded-2"
                        focusStyle="border-0"
                        value={field.value}
                        onChange={field.onChange}
                        numInputs={4}
                        renderInput={(props) => (
                            <input
                                {...props}
                                onInput={(e) => {
                                    // Ensure only numeric characters remain
                                    e.currentTarget.value = e.currentTarget.value.replace(/\D/g, "");
                                }}
                            />
                        )}
                    />

                )}
                rules={{ required: 'Please enter your 4 digit OTP' }}
            />
            {errors.otp && <p style={{ fontSize: "13px", color: "#f04438", margin: "0" }}>
                {errors.otp}
            </p>}
            {/* {wrongOtp && (
                <p style={{ fontSize: "13px", color: "#f04438", margin: "0" }}>
                    Incorrect code
                </p>
            )} */}
            {expiredOtp && (
                <p style={{ fontSize: "13px", color: "#f04438", margin: "0" }}>
                    Otp expired
                </p>
            )}
            {remainResendAttempt > 0 && (
                <p style={{ fontSize: "13px", color: "green", margin: "0" }}>
                    {remainResendAttempt} resend OTP attempt(s) left.
                </p>
            )
        
        }
        {(remainResendAttempt === 0  && !cameFromUpdateEmail)  && (
                <p style={{ fontSize: "13px", color: "green", margin: "0" }}>
                    0 resend OTP attempt(s) left. Please check if you have provided the correct email address. Otherwise, proceed to verify later.
                </p>
            )
        
        }
            {remainResendAttempt > 0 && !(seconds===0)  &&   (
                <p style={{ fontSize: "13px", color: "#f04438", margin: "0" }}>
                    Please wait {seconds} seconds before requesting for another OTP again.
                </p>
            )}

            
            <div  className="mt-4">
                <Button
                    name={`${isLoading?.verifyEmail ? "Loading..." : "Verify email"}`}
                    disable={isLoading?.verifyEmail || !(watchOtp?.toString()?.length === 4)}
                    onClick={(e) => handleSubmit(e)}
                />
            </div>

            

            <div
                className="mt-3 text-center "
                onClick={() => seconds === 0 ? handleResendOtp() : ''}
            >
                <p className="text-secondary fs-6">
                Didn’t receive  the email? {" "}
                    <span className={`${seconds === 0 ? 'text-primary fw-bold cursor-pointer' : 'text-secondary fw-bold' }`}>
                        {isLoading?.ClicktoResend ? "Loading..." : "Request resend"}
                    </span>
                </p>
            </div>

            
           

           
                <div
                    className="mt-3 text-center cursor-pointer"
                    onClick={() => { setShowEmail(true) }}
                >
                    <p className="text-secondary fs-6">
                        Invalid email? {" "}
                        <span className="text-primary fw-bold">
                            Click to update email
                        </span>
                    </p>
                </div>

                <div
                className={`${
                    timeLeft === 0 ? 'cursor-pointer text-secondary' : 'text-white invisible'
                } mt-3 text-center`}
            >
                <p className="text-secondary fs-6">
                    I will {" "}
                    <span
                    className={`${
                        timeLeft === 0 ? 'text-primary' : 'text-secondary invisible'
                    } fw-bold`}
                    onClick={timeLeft === 0 ? handleVerifyLater : undefined}
                    >
                    {"verify email later"}
                    </span>
                </p>
            </div>
            

            
        </div>
            
            </Modal.Body>
           
           
            
        </Modal>
    );
}

export default GuestOtp;