import React, { useState } from "react";
import Account from "../../account/Account";
import Button from "../../../components/common/button/Button";
import UploadDoc from "../../../assets/images/LearnAndEarn/upload.webp";
import { useForm , Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { claimRewardService } from "../../../services/learnAndEarn.service";
import SuccessMessageModal from "../../../components/Modals/SuccessMessageModal";
import { selectCountryList } from "../../../redux/userSlice";
import { useSelector } from "react-redux";
import Select from 'react-select'
const BeneficiaryDetails = () => {
  // const navigate = useNavigate();
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onBlur",
  });

  const [loading, setLoading] = useState(false);

  const countryList = useSelector(selectCountryList);

  const fileInputRef = useRef(null);

  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const [selectedDocumentType, setSelectedDocumentType] =
    useState("identity_card");

  const handleDocumentTypeChange = (event) => {
    setSelectedDocumentType(event.target.value);
    // Reset document_file if the document type changes
    setValue("document_file", []);
  };

  const uploadedFiles = watch("document_file") || [];
  const uploadedFiles_second = watch("document_file_second") || [];

  if (
    (errors.document_file || errors.document_file_second) &&
    !errors.withdraw_points
  ) {
    fileInputRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  const countryOptions = countryList?.map(country => ({
    value: country.isd_code,
    label: `${country.name} ${country.isd_code}`,
    code: country.isd_code, // Store the code separately for use in single-value display
  }));

  // Custom single value renderer to only display the country code after selection
  const formatSelectedValue = (selectedOption) => selectedOption?.code;

  const onSubmit = async (formData) => {
    setLoading(true);
    let result = await claimRewardService({
      withdraw_points: formData?.withdraw_points,
      official_document_type: formData?.official_document_type,
      document_file: formData?.document_file[0],
      document_file_second: formData?.document_file_second[0],
      full_name: formData?.full_name,
      email: formData?.email,
      phone_number: `${formData?.phone_number}`,
      phone_code:`+${formData?.country_code}`,
      address: formData?.address,
      tax_id: formData?.tax_id,
      bank_country: formData?.bank_country,
      bank_name: formData?.bank_name,
      bank_account_number: formData?.bank_account_number,
      bank_account_holder_name: formData?.bank_account_holder_name,
      swift_code: formData?.swift_code,
      has_reskills_user: formData?.has_reskills_user,
      terms_and_conditions_accepted: formData?.terms_and_conditions_accepted,
      
    });

    if (result?.response?.data?.error) {
      toast.error(result?.response?.data?.error || "Something went wrong");
    } else if (result?.data?.message) {
      toast.success(
        result?.data?.message || "Withdrawal request submitted successfully."
      );
      setShow(true);
    } else if (result?.response?.data?.message) {
      toast.error(result?.response?.data?.message || "Something went wrong");
    }
    setLoading(false);
  };

  return (
    <Account>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tran-container d-flex justify-content-center">
          <div className="main-reward-container">
            <h4 className=" fw-bold">ReSkills Reward Points Withdrawal</h4>
            <small>
              After you fill out this withdrawal request form, we will contact
              you to go over details and availability before the order is
              completed. If you have any further questions about the withdrawal
              process, you may contact us at {" "}
              <a href="mailto:support@reskills.com">support@reskills.com</a>.
            </small>

            <div className="shadow-sm rounded my-4 border border-light flex-column p-3">
              <div className="fw-bold mx-2">Withdrawal Points</div>
              <div className="m-2">
                <p className="fs-6">
                  {" "}
                  You are required to have a minimum of 50 points in your
                  account before you can withdraw. Any withdrawal request for an
                  amount more than what you have in your account automatically
                  be rejected and will not be processed.
                </p>

                <div className="my-4">
                  <p className="fw-semibold my-2 fs-6">
                    How many points would you like to withdraw ?
                  </p>
                  <input
                    className="form-control my-2"
                    type="text"
                    placeholder="Enter Here"
                    name="withdraw_points"
                    {...register("withdraw_points", {
                      required: "Please enter the number of points to withdraw",
                      pattern: {
                        value: /^(5[0-9]|[6-9][0-9]|[1-9]\d{2,})(\.\d{1,2})?$/,
                        message:
                          "Please enter a value greater than or equal to 50",
                      },
                      onChange: (e) => {
                        const value = e.target.value;
                        const regex = /^\d+(\.\d{0,2})?$/;

                        if (!regex.test(value)) {
                          e.target.value = value.slice(0, -1); // Remove extra decimal places
                        }
                      },
                    })}
                  />
                  {errors.withdraw_points && (
                    <p style={{ fontSize: "13px", color: "#f04438" }}>
                      {errors.withdraw_points.message}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <h4 className=" fw-bold">Beneficiary Details</h4>
            <small>
              You're required to fill in the information details of the person
              who will receive this reward in order for us to complete the
              payout transaction successfully.
            </small>

            {/* Personal information */}
            <div className="shadow-sm rounded my-4 border border-light flex-column p-3">
              <div className="fw-bold mx-2">Personal Information</div>
              <div className="m-2">
                <div>
                  <div className="fw-semibold  fs-6">Full name *</div>
                  <label style={{ fontSize: "13px", color: "#808080" }}>
                    Full name must match the full name on the official document
                    you provide.
                  </label>

                  <input
                    className="form-control my-1"
                    type="text"
                    placeholder="Enter Here"
                    {...register("full_name", {
                      required: "Full name is required",
                    })}
                  />
                  {errors.full_name && (
                    <p style={{ fontSize: "13px", color: "#f04438" }}>
                      {errors.full_name.message}
                    </p>
                  )}
                </div>
                {/* Official document selection */}
                <div className="my-4 ">
                  <div className="fw-bold mx-2">
                    Identity document verification *
                  </div>
                  <label
                    className="  mx-2"
                    style={{ fontSize: "13px", color: "#808080" }}
                  >
                    Please provide one of the following official Document as
                    proof of identity.
                  </label>

                  <div className="m-2">
                    <div className="form-check d-flex gap-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="identity_card"
                        id="documentIdentityCard"
                        name="official_document_type"
                        defaultChecked={true}
                        {...register("official_document_type", {
                          required: "Please select an official document type",
                        })}
                        onChange={handleDocumentTypeChange}
                      />
                      <p className="m-0 fs-6">Identity Card</p>
                    </div>
                    <div className="form-check d-flex gap-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="passport"
                        id="documentPassport"
                        name="official_document_type"
                        {...register("official_document_type", {
                          required: "Please select an official document type",
                        })}
                        onChange={handleDocumentTypeChange}
                      />
                      <p className="m-0 fs-6">Passport</p>
                    </div>
                    {errors.official_document_type && (
                      <p style={{ fontSize: "13px", color: "#f04438" }}>
                        {errors.official_document_type.message}
                      </p>
                    )}
                  </div>

                  {/* Document upload */}
                  <div className="shadow-sm rounded my-4 border bg-reward flex-column p-3">
                    <div className="fw-bold mx-2">
                      Upload the official document here.*
                    </div>
                    <div className="text-secondary mx-2">
                      Upload {selectedDocumentType === "passport" && "1"}{" "}
                      supported{" "}
                      {selectedDocumentType === "passport" ? "file" : "files"}:
                      PDF, document, or image. Max 100 MB.
                    </div>

                    {selectedDocumentType === "identity_card" && (
                      <div className=" upload-select">
                        <div className="my-3" style={{ cursor: "pointer" }}>
                          <label htmlFor="file-upload-front">
                            <img
                              src={UploadDoc}
                              alt="upload front"
                              style={{ cursor: "pointer" }}
                              ref={fileInputRef}
                            />
                          </label>
                          <input
                            id="file-upload-front"
                            type="file"
                            accept=".pdf,.doc,.docx,.jpg,.jpeg,.webp"
                            style={{ display: "none" }}
                            {...register("document_file", {
                              required:
                                "Please upload the Front photo of ID Proof",
                            })}
                          />
                          {
                            <p className="mx-2 text-primary fw-semibold">
                              Uploaded front file:{" "}

                              <p className="text-truncate">
                                {uploadedFiles[0] && uploadedFiles[0]?.name}
                              </p>

                            </p>
                          }
                        </div>

                        <div className="my-3" style={{ cursor: "pointer" }}>
                          <label htmlFor="file-upload-back">
                            <img
                              src={UploadDoc}
                              alt="upload back"
                              style={{ cursor: "pointer" }}
                            />
                          </label>
                          <input
                            id="file-upload-back"
                            type="file"
                            accept=".pdf,.doc,.docx,.jpg,.jpeg,.webp"
                            style={{ display: "none" }}
                            {...register("document_file_second", {
                              required:
                                "Please upload the Back photo of ID Proof",
                            })}
                          />
                          {
                            <p className="mx-2 text-primary fw-semibold ">
                              Uploaded back file:{" "}

                              <p className="text-truncate">
                              {uploadedFiles_second[0] &&
                                uploadedFiles_second[0]?.name}
                              </p>
                              

                            </p>
                          }
                        </div>
                      </div>
                    )}

                    {selectedDocumentType === "passport" && (
                      <div className="my-3" style={{ cursor: "pointer" }}>
                        <label htmlFor="file-upload-passport">
                          <img
                            src={UploadDoc}
                            alt="upload passport"
                            style={{ cursor: "pointer" }}
                            ref={fileInputRef}
                          />
                        </label>
                        <input
                          id="file-upload-passport"
                          type="file"
                          accept=".pdf,.doc,.docx,.jpg,.jpeg,.webp"
                          style={{ display: "none" }}
                          {...register("document_file", {
                            required: "Please upload the Passport",
                          })}
                        />
                        {
                          <p className="mx-2 text-primary fw-semibold">
                            Uploaded file:{" "}
                            {uploadedFiles[0] && uploadedFiles[0]?.name}
                          </p>
                        }
                      </div>
                    )}

                    {errors.document_file && (
                      <p style={{ fontSize: "13px", color: "#f04438" }}>
                        {errors.document_file.message}
                      </p>
                    )}
                    {errors.document_file_second && (
                      <p style={{ fontSize: "13px", color: "#f04438" }}>
                        {errors.document_file_second.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mail-phone-flex gap-3">
                  <div className="w-100">
                    <p className="fw-semibold my-2 fs-6">Email*</p>
                    <input
                      className="form-control my-2"
                      type="text"
                      placeholder="Enter Here"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Entered value does not match email format",
                        },
                      })}
                    />
                    {errors.email && (
                      <p style={{ fontSize: "13px", color: "#f04438" }}>
                        {errors.email.message}
                      </p>
                    )}
                  </div>

                  <div className="w-100 ">
                    <p className="fw-semibold my-2 fs-6">Phone Number*</p>
                    <div className="mail-phone-flex gap-3">
                      <div className="">
                      <Controller
                        name="country_code"
                        control={control}
                        rules={{ required: "Country Code is required" }}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <Select
                              {...field}
                              options={countryOptions}
                              value={countryOptions?.find(option => option.value === watch("country_code"))}
                              onChange={(selectedOption) => field?.onChange(selectedOption?.value)}
                              placeholder="Select"
                              isClearable
                              getOptionLabel={(option) => option.label} // Show name and code in dropdown
                              getOptionValue={(option) => option.value} // Sets the value to country code
                              formatOptionLabel={(option, { context }) => 
                                context === 'value' ? formatSelectedValue(option) : option.label
                              } // Only show code after selection
                              styles={{
                                menu: (base) => ({
                                  ...base,
                                  width: '200px',  // Increase the width of the dropdown options

                                }),

                                control: (base) => ({
                                  ...base,
                                  width: '130px',  // Keep the input select box width unchanged
                                }),
                               
                              }}
                            />
                          </>
                        )}
                      />
                      </div>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Here"
                          {...register("phone_number", {
                            required: "Phone number is required",
                            maxLength: {
                              value: 15,
                              message: "Phone number should not be greater than 15 digits",
                            },
                            minLength: {
                              value: 9,
                              message: "Phone number should be greater than 9 digits",
                            },
                          })}
                        />
                      </div>
                    </div>
                    {errors.country_code && (
                      <p style={{ fontSize: "13px", color: "#f04438" }}>
                        {errors.country_code.message}
                      </p>
                    )}

                    {errors.phone_number && (
                      <p style={{ fontSize: "13px", color: "#f04438" }}>
                        {errors.phone_number.message}
                      </p>
                    )}
                  </div>

                </div>
                <div className="my-2">
                  <p className="fw-semibold my-2 fs-6">Address*</p>
                  <textarea
                    className="form-control my-2"
                    placeholder="Enter Here"
                    {...register("address", {
                      required: "Address is required",
                    })}
                  />
                  {errors.address && (
                    <p style={{ fontSize: "13px", color: "#f04438" }}>
                      {errors.address.message}
                    </p>
                  )}
                </div>
                <div className="my-2">
                  <p className="fw-semibold my-2 fs-6">Tax ID</p>
                  <label style={{ fontSize: "13px", color: "#808080" }}>
                    Tax ID field is optional, if you do not have one, please
                    leave it blank.
                  </label>
                  <input
                    className="form-control my-2"
                    type="text"
                    placeholder="Enter Here"
                    {...register("tax_id")}
                  />
                </div>
              </div>
            </div>

            {/* Bank details */}
            <div className="shadow-sm rounded my-4 border border-light flex-column p-3">
              <div className="fw-bold mx-2">Bank details</div>
              <div className="m-2">
                <div className="my-2">
                  <p className="fw-semibold my-2 fs-6">Bank Country *</p>
                  <select
                    className="form-control my-2"
                    {...register("bank_country", {
                      required: "Bank Country is required",
                    })}
                  >
                    <option value="">Select a country</option>
                    {countryList?.map((country, index) => (
                      <option key={index} value={country?.name}>
                        {country?.name}
                      </option>
                    ))}
                  </select>
                  {errors.bank_country && (
                    <p style={{ fontSize: "13px", color: "#f04438" }}>
                      {errors.bank_country.message}
                    </p>
                  )}
                </div>
                <div className="my-2">
                  <p className="fw-semibold my-2 fs-6">Name of the Bank*</p>
                  <input
                    className="form-control my-2"
                    type="text"
                    placeholder="Enter Here"
                    {...register("bank_name", {
                      required: "Bank name is required",
                    })}
                  />
                  {errors.bank_name && (
                    <p style={{ fontSize: "13px", color: "#f04438" }}>
                      {errors.bank_name.message}
                    </p>
                  )}
                </div>
                <div className="my-2">
                  <p className="fw-semibold my-2 fs-6"> Account holder name*</p>
                  <input
                    className="form-control my-2"
                    type="text"
                    placeholder="Enter Here"
                    {...register("bank_account_holder_name", {
                      required: "Bank account holder name is required",
                    })}
                  />
                  {errors.bank_account_holder_name && (
                    <p style={{ fontSize: "13px", color: "#f04438" }}>
                      {errors.bank_account_holder_name.message}
                    </p>
                  )}
                </div>

                <div className="">
                  <div className="w-100">
                    <p className="fw-semibold my-2 fs-6">
                      Bank Account Number*
                    </p>
                    <label
                      className=" "
                      style={{ fontSize: "13px", color: "#808080" }}
                    >
                      Please provide a complete and accurate local bank account
                      details. Kindly note that we do not accept e-wallets or
                      third-party payment service accounts.
                    </label>

                    <input
                      className="form-control my-2"
                      type="text"
                      placeholder="Enter Here"
                      {...register("bank_account_number", {
                        required: "Bank account number is required",
                      })}
                    />
                    {errors.bank_account_number && (
                      <p style={{ fontSize: "13px", color: "#f04438" }}>
                        {errors.bank_account_number.message}
                      </p>
                    )}
                  </div>
                  <div className="w-100">
                    <p className="fw-semibold my-2 fs-6"> SWIFT code*</p>
                    <input
                      className="form-control my-2"
                      type="text"
                      placeholder="Enter Here"
                      {...register("swift_code", {
                        required: "Bank SWIFT code is required",
                      })}
                    />
                    {errors.swift_code && (
                      <p style={{ fontSize: "13px", color: "#f04438" }}>
                        {errors.swift_code.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="shadow-sm rounded my-4 border border-light  flex-column p-3 ">
              <div className="fw-bold mx-2">
                Is this beneficiary also the owner of this ReSkills Account? *
              </div>
              <div className="m-2 ">
                <div className="form-check d-flex gap-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    value={1}
                    id="isAccountOwnerYes"
                    name="isAccountOwner"
                    {...register("has_reskills_user", {
                      required: "Please select Yes or No",
                    })}
                  />
                  <p className="m-0 fs-6">Yes</p>
                </div>
                <div className="form-check d-flex gap-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    value={0}
                    id="isAccountOwnerNo"
                    name="isAccountOwner"
                    {...register("has_reskills_user", {
                      required: "Please select Yes or No",
                    })}
                  />
                  <p className="m-0 fs-6">No</p>
                </div>
                {errors.has_reskills_user && (
                  <p style={{ fontSize: "13px", color: "#f04438" }}>
                    {errors.has_reskills_user.message}
                  </p>
                )}
              </div>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name="terms"
                value={1}
                {...register("terms_and_conditions_accepted", {
                  required: "Please accept the terms and conditions",
                })}
              />
              <label className="form-check-label">
                I read and agreed to the{" "}
                <label
                  className="text-primary fw-semibold cursor-pointer"
                  onClick={() => {
                    navigate("/account/learn-and-earn/term-and-condition");
                  }}
                >
                  Terms and Condition.
                </label>
              </label>
            </div>
            {errors.terms_and_conditions_accepted && (
              <p style={{ fontSize: "13px", color: "#f04438" }}>
                {errors.terms_and_conditions_accepted.message}
              </p>
            )}

            <div className="my-3 mb-5 max-width-reward">
              <Button
                name={loading ? "Loading..." : "Submit"}
                type="submit"
                disable={loading}
              />
            </div>
          </div>
        </div>
      </form>
      <SuccessMessageModal shows={show} setShow={setShow} />
    </Account>
  );
};

export default BeneficiaryDetails;