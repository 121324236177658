import React, { useState, useEffect ,useCallback } from 'react';
import Account from '../account/Account';
import searchIcon from "../../assets/images/Navbar/search-Icon.webp";

import '../../assets/scss/pages/userCertificates/UserCertificates.scss'


//Api
import { useDispatch } from 'react-redux';

import { getCertificateService } from '../../services/certificate.service';
import CertificatePaginate from '../../components/common/paginate/CertificatePaginate';
import { trackPageView } from '../../utils/googleAnalytics';

const UserCertificates = () => {

    const [userCertificates, setUserCertificates] = useState()
    const [selectedClass, setSelectedClass] = useState('')
    const [totalItem, setTotalItem] = useState();
    const dispatch = useDispatch();
    const [searchClass, setSearchClass] = useState();


     useEffect(() => {
              document.title = "Reskills | Certificate"
              trackPageView('Certificate');
            }, []);



    /**
       * @function fetchCertificateDetails
       * @params
       * @description used to fetch Certificate details
   */
    const fetchCertificateDetails = async () => {
        const result = await getCertificateService({
            class_type: selectedClass,
            perPageItem: 8,
            page: 1,
        });
        // dispatch(setCertificateDetail(result?.data?.certificates?.data))
        setUserCertificates(result?.data)
        setTotalItem(result?.data?.certificates?.total);

    };


    useEffect(() => {
        fetchCertificateDetails();
        // eslint-disable-next-line
    }, [selectedClass]);
    const handleChange = (event) => {
        setSelectedClass(event.target.value);
    };

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 500);
        };
    };
    const handleSearch = async (event) => {
        const { value } = event?.target;
        setSearchClass(value)
        
    };

    const optimisedVersion = useCallback(debounce(handleSearch), []);


    return <Account>
        <div className='main-div'>
            <h4>Certificates</h4>
            <div className='d-flex rounded shadow my-3 user-cards'>
                <div className='d-flex'>
                    <div className=' user-p-2 mx-2 info-div'>
                        <div className='fw-bold'>Total certificates</div>
                        <h5 className='m-t-2 text-primary fw-bold'>{userCertificates?.total_certificates || '0'}</h5>
                    </div>
                    <div className=' user-p-2 mx-2 info-div'>
                        <div className='fw-bold'>OnLive certificates</div>
                        {userCertificates?.typewise_count
                            .filter(item => item?.type_name?.toLowerCase() === 'onlive')
                            .map((item) => (
                                <h5 className='m-t-2 text-primary fw-bold'>{item.count}</h5>
                            ))}
                    </div>
                </div>
                <div className='d-flex'>
                    <div className=' user-p-2 mx-2 info-div'>
                        <div className='fw-bold'>OnStage certificates</div>
                        {userCertificates?.typewise_count
                            .filter(item => item?.type_name?.toLowerCase() === 'onstage')
                            .map((item) => (
                                <h5 className='m-t-2 text-primary fw-bold'>{item.count}</h5>
                            ))}
                    </div>
                    <div className=' user-p-2 mx-2 info-div'>
                        <div className='fw-bold'>OnFerence certificates</div>
                        {userCertificates?.typewise_count
                            .filter(item => item?.type_name?.toLowerCase() === 'onference')
                            .map((item) => (
                                <h5 className='m-t-2 text-primary fw-bold'>{item.count}</h5>
                            ))}
                    </div>
                </div>
            </div>
            <div className='my-4 rounded shadow px-2'>
                <h5 className='fw-bold py-3 px-4'>ReSkills Certificate Authentication</h5>
                <div className="onlive-filter-container d-flex align-items-center justify-content-between  mb-4 mt-3 ">
              <div className="search-container">
                <img src={searchIcon} alt="icon" />
                <input
                  className="form-control left-search-padding"
                  type="search"
                  placeholder="Search By Title, Keywords"
                  aria-label="Search"
                  onChange={optimisedVersion}
                />
              </div>
            <div className='d-flex my-full-w'>
                       
                <div className="dropdown-container  ">
                <select
                    className="dropdown-itemMenu form-control "
                            onChange={handleChange}>
                                <option value="" >All Type</option>
                                <option className="dropdown-items" value="1">OnLive</option>
                                <option className="dropdown-items" value="2">OnStage</option>
                                <option className="dropdown-items" value="5">OnFerence</option>
                            </select>
                        </div>
                    </div>

                </div>

                {/* <div className='w-100 px-2'>
                    {userCertificates?.certificates?.data?.map((item, index)=>{
                        return(
                            <CertificateCard
                                 cardDetail={item} 
                            />
                        )
                    })
                    }
                </div> */}
                <div className='w-100 px-2'>
                    <CertificatePaginate totalItem={totalItem} selectedClass={selectedClass} searchQuery={searchClass}/>
                </div>

            </div>
        </div>
    </Account>
};

export default UserCertificates;