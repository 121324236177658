import {React ,useEffect} from 'react';
import { useSelector } from 'react-redux';

import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { selectLearnAndEarnSubscriptionDetail } from '../../../redux/learnAndEarnSubscriptionSlice';
import { paymentPageService ,applyScratchCodeService } from '../../../services/learnAndEarn.service';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { paymentService } from '../../../services/subscription.service';


function SummaryModal({show , onHide , subscriptionType }) {

    const [isChecked, setIsChecked] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isLoading , setLoading] = useState(false)

    const navigate =useNavigate();

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        setShowError(false); // Hide error when checkbox is checked
    };
    


    
    const subscriptionDetails = useSelector(selectLearnAndEarnSubscriptionDetail);
     

    /**
        * @function handlePayment
        * @params
        * @description used to handle payment
    */
    const handlePayment = async () => {
        if (!isChecked) {
            setShowError(true);
            // toast.error("You must agree to the Terms & Conditions to proceed");
            return;
        }
        setLoading(true)
        const result = await paymentPageService();
        if (result?.data?.data?.respDesc === "Success") {
            window.location.href = result?.data?.data?.webPaymentUrl
        } else {
            toast.error(result?.response?.data?.error || "Your payment is failed")
            // navigate('/account/learn-and-earn')
            
        }
        setLoading(false)
    }

    const handleNormalPayment = async () => {
        if (!isChecked) {
            setShowError(true);
            // toast.error("You must agree to the Terms & Conditions to proceed");
            return;
        }
        setLoading(true)
        const result = await paymentService();
        if (result?.data?.data?.respDesc === "Success") {
            window.location.href = result?.data?.data?.webPaymentUrl
        } else {
            toast.error(result?.response?.data?.error || "Your payment is failed")
        }

        setLoading(false)

    }


    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
            <Modal.Header className="px-4 pb-0 border-0" closeButton>
                <Modal.Title>
                    <h3 className="fw-bold">Checkout Summary</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {/* <Form onSubmit={handleSubmit(onSubmit)}> */}
                <div className="w-100 bg-muted rounded">
                    <small className='text-primary fw-semibold'>{subscriptionType === 'LEP' ?'2':'1'} Year Premium Subscription</small>
                    <h1 className='fw-bold'>{subscriptionType === 'LEP' ?'$26' : '$13'}</h1>
                    {/* <small className='text-muted'>(Including ${subscriptionDetails?.processing_amount} service charge <br/>fee)</small> */}
                    <div className='d-flex justify-content-between fs-6 my-2 fw-semibold'>
                        <div>Subtotal</div>
                        <div>{subscriptionType === 'LEP' ?'$24.00' : '$12.00'}</div>
                    </div>
                    <div className='d-flex justify-content-between fs-6 my-2 fw-semibold'>
                        <div>Processing Charge</div>
                        <div>${subscriptionType === 'LEP' ? subscriptionDetails?.processing_amount :'1' }.00</div>
                    </div>
                    
                    <div className='d-flex justify-content-between  my-2 fw-bold'>
                        <div>Total</div>
                        <div className='text-bold '>{subscriptionType === 'LEP' ?'$26.00' :'$13.00'}</div>
                    </div>
                    <div className="d-flex gap-2 my-2">
                        <input 
                            type="checkbox"
                            required
                            onChange={handleCheckboxChange}
                        />
                        <small className={showError ? "text-danger" :''}>
                            I agree to the
                            <small className="text-primary mx-1 fw-semibold cursor-pointer" onClick={()=>{navigate(subscriptionType === 'LEP' ?'/account/learn-and-earn/term-and-condition' : '/subscription/payment-term-and-condition')}}>Terms & Conditions</small>
                        </small>

                        
                    </div>
                    <small className='fw-bold'>
                            Have a gift card?
                            <small className="text-primary mx-1 fw-bold cursor-pointer" onClick={()=>{navigate('/redeem')}}>Redeem here</small>
                        </small>
                    {/* {activeModal=== 'summary-scratch-code' &&
                       
                        <>
                            <div className='row'>
                                <div className='col-8'>
                                <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Here"
                                        {...register('scratch_code', {
                                            required: "Scratch code is required"
                                        })}
                                    />
                                </div>
                            <div className='col-4'> 
                                    <button  className='form-control btn btn-primary '>Continue</button>
                            </div>
                            
                            </div>
                            </>
                        
                    } */}
                    
                    
                </div>
            {/* </Form> */}
            </Modal.Body>
           
            <Modal.Footer>
            <div className='d-flex justify-content-end w-100 '>
                        <div className='d-flex   '>
                            <button  className='form-control  text-primary btn  mx-2 border solid subscriber-cancel' onClick={onHide}>Cancel</button>
                            
                                <button  
                                    className='form-control btn btn-primary full-width'
                                    onClick={subscriptionType === 'LEP'? handlePayment : handleNormalPayment}
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Loading...' : 'Proceed to Pay'}
                                </button>
                          
                        </div>
                    </div>
            </Modal.Footer>
            
        </Modal>
    );
}

export default SummaryModal;