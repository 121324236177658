import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getToken } from "./Auth.Service";
import PropTypes from "prop-types";

// handle the private routes
const PrivateRoute = (props) => {
    const tokens = getToken();
    const location = useLocation();
    
    if (tokens?.tokenAccess) {
        return <Outlet>{props?.children} </Outlet>;
    } else {
        // Store the attempted URL before redirecting to login
        sessionStorage.setItem("redirectAfterLogin", location.pathname);
        return <Navigate to="/login" replace={true} />;
    }
}

export default PrivateRoute;

PrivateRoute.prototypes = {
    children: PropTypes.node.isRequired
}
