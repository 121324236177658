import { useState, useCallback, useEffect } from "react";
import {  useSelector } from "react-redux";

//importing image
import searchIcon from "../../assets/images/Onlive/searchIcon2.webp";

import "../../assets/scss/pages/onLive/onLive.scss";


//importing redux
import Layout from "../../components/common/layout/layout";
import { Tab, Tabs } from "react-bootstrap";
import { selectCategoryList } from "../../redux/categorySlice";
import MyLearningPaginate from "../../components/common/paginate/MyLearningPaginate";
import { trackPageView } from "../../utils/googleAnalytics";

const MyLearning = () => {
    const categories = useSelector(selectCategoryList);

    const [key, setKey] = useState('previous-learnings');
    const [selectedCategory, setSelectedCategory] = useState();
    const [searchClass, setSearchClass] = useState();


    useEffect(() => {
        document.title = "Reskills | My Learning"
        trackPageView("MyLearning");
      }, []);



    // const badges = ['OnLive', 'OnStage', 'OnFerence'];
    const badges = [
        {
            name: 'OnLive',
            slug: 'onlive'
        },
        {
            name: 'OnStage',
            slug: 'onstage'
        },
        {
            name: 'OnFerence',
            slug: 'onference'
        }
    ]

    const [activeBadge, setActiveBadge] = useState('onlive');

    const handleBadgeClick = (badge) => {
        setActiveBadge(badge?.slug);
    };

    const handleSearch = async (event) => {
        const { value } = event?.target;
        setSearchClass(value)
    };


    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 500);
        };
    };

    // eslint-disable-next-line
    const optimisedVersion = useCallback(debounce(handleSearch), []);


       return (
        <Layout currentPage="My Learnings">
            <div className="custom-container max-w-home">
                <div className="d-flex flex-column gap-3 mx-md-5 mx-3 mt-3 align-items-start my-learning-tab">
                    <p className="fs-30 fw-bold">My Learnings</p>
                    <Tabs
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="w-100"
                    >
                        <Tab eventKey="previous-learnings" title="Previous Learnings">
                        </Tab>
                        <Tab eventKey="my-registrations" title="My Registrations">
                        </Tab>
                        <Tab eventKey="my-playlist" title="My Playlist">
                        </Tab>
                    </Tabs>
                    <div className="w-100 onlive-filter-container d-flex align-items-center justify-content-between  mb-4">
                    <div className="search-container">
                        <img src={searchIcon} alt="icon" />
                        <input
                            className="form-control left-search-padding"
                            type="search"
                            placeholder="Search By Title, Keywords"
                            aria-label="Search"
                            onChange={optimisedVersion}
                        />
                    </div>
                    <div className="dropdown-container">
    <select
        className="dropdown-itemMenu"
        onChange={(e) =>
            setSelectedCategory(e.target.value === "Select Category" ? "" : e.target.value)
        }
    >
        <option value="Select Category">Select Category</option>
        {categories?.map((item) => (
            <option key={item?.slug} className="cursor-pointer" value={item?.slug}>
                {item?.name}
            </option>
        ))}
    </select>
</div>

                    </div>
                    {(key === "my-registrations" || key === "previous-learnings") && badges.length > 0 && (
                        <div className="d-flex">
                            {badges.map((badge) => (
                                <div
                                    key={badge}
                                    className={`badge rounded-pill px-4 py-3 fs-6 mx-2 cursor-pointer ${activeBadge === badge?.slug ? 'bg-primary text-white' : 'bg-light text-primary'}`}
                                    onClick={() => handleBadgeClick(badge)}
                                >
                                    {badge?.name}
                                </div>
                            ))}
                        </div>
                    )}
                

                    <MyLearningPaginate tabKey={key} activeBadge={activeBadge} selectedCategory={selectedCategory} searchQuery={searchClass}/>
                </div>
            </div>
        </Layout>
    );
};

export default MyLearning;
