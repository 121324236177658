

import React from 'react';
import Tablet from '../../../../assets/images/LearnAndEarn/bag.png';
import RightArrow from '../../../../assets/images/LearnAndEarn/right-arrow.png'
import Button from '../../button/Button';
import ButtonWithArrow from '../../button/ButtonWithArrow';


const MerchandiseCard = () => {
    return (
        <div>
            <div className='bg-white border' style={{ width: "200px" , borderRadius:'10px' }}>
                <img src={Tablet} style={{width:'198px' , height:'160px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}/>
                <div className='my-2 mx-3'>
                    <div className='fw-bold text-center'>Xiaomi Redmi</div>
                    <div className=' text-center' style={{fontSize:'12px'}}>
                        Unlock exclusive gadgets as  by by to completing tasks, to to milestones, or participating in special events.
                    </div>
                    <div className='fw-semibold  text-center text-primary my-1'  style={{fontSize:'16px'}}>
                        2,500 points
                    </div>
                    <ButtonWithArrow name='Claim Now'/>
                </div>
                

            </div>
            
        </div>
    );
};

export default MerchandiseCard;