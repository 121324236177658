//importing styles
import Skeleton from "react-loading-skeleton";
import "../../../../assets/scss/components/card/featureCard/featureCard.scss";
import Image from "../../image";
import PlaceHolderImg from "../../../../assets/images/remove.png"

const FeatureCard = ({
  cardType,
  cardContent,
  setProfessionName,
  activeCard,
  setActiveCard,
}) => {
  const handleClick = (e, item) => {
    e.stopPropagation();
    setActiveCard(item);
    setProfessionName(item?.title);
  };



  return (
    <>
      {cardType === "vertical" ? (
        // vertical card
    <div className="feature-card-main cursor-pointer mx-2 ">

        <div className="vertical-card bg-grey mx-2 ">
          <div className="vertical-card-body px-3">
            <div className="card-left-body">
              {cardContent?.image?
              <div className="vertical-card-image-container  mx-auto px-4">
                <Image
                  src={cardContent?.image && cardContent?.image}
                  alt="featureIcon"
                  className="vertical-card-image"
                  defaultImage={PlaceHolderImg}
                /> 
              </div>:
              <div className="p-4">
                <Skeleton circle height={80} width={80}/>
              </div>
              }
            </div>
            <div className="card-right-body">
              <h6 className="h5 text-dark fw-bold">{cardContent?.name || <Skeleton/>}</h6>
              {/* <p className="vertical-card-text fw-bold">{cardContent?.description}</p> */}
            </div>
          </div>
        </div>
    </div>
      ) : cardType === "verticalWithHover" ? (
    <div className="feature-card-main cursor-pointer mx-2 ">
        <div
          className={
            activeCard?.id === cardContent?.id
              ? "verticalWithHover-Active-card"
              : "verticalWithHover-card bg-grey"
          }
          // style={{ width: "190px" }}
          onClick={(e) => handleClick(e, cardContent)}
        >
          <div className="verticalWithHover-card-body">
            <div className="card-left-body">
              {activeCard?.id === cardContent?.id ? (
                <img src={cardContent.activeImage} alt="featureIcon" />
              ) : (
                <img src={cardContent.defaultImage} alt="featureIcon" />
              )}
            </div>
            <div className="card-right-body mt-3">
              <h6 className="verticalWithHover-card-title fw-bold fs-6">
                {cardContent.title}
              </h6>
              {/* <p className="verticalWithHover-card-text">1500+</p> */}
            </div>
          </div>
        </div>
    </div>
      ) : (
    <div className="feature-card-main cursor-pointer mx-2  feature-card-width">

        <div className="card shadow-sm bg-white rounded  width-b">
          <div className="card-body feature-card-main   ">
            <div className="card-left-body">
              <div className="d-flex justify-content-center align-items-center" >
                {cardContent?.image ?
                <div className=" rounded-circle d-flex justify-content-center align-items-center" style={{ width: '80px', height: '80px', background: "#584091" }}>
                  <div className=" rounded-circle d-flex justify-content-center align-items-center" style={{ width: '60px', height: '60px', background:"#e4d5f5"  }}>
                    <img src={cardContent?.image} alt="featureIcon" style={{ width: '40px', height: '40px' }} />
                  </div>
                </div>:
                  <Skeleton circle height={80} width={80}/>
                }
              </div>
            </div>
            <div className="card-right-body">

              {cardContent?.key ?
                <h6 className="card-title fw-bold">{cardContent?.key}</h6>
                  :<Skeleton width={100}/>
              }
              {cardContent?.value?
              <p className="h3 fw-bold font-center">{cardContent?.value}</p>
                : <Skeleton height={20}/>
            }
            </div>
          </div>
        </div>
      </div>
      )}
    
    </>
  );
};

export default FeatureCard;
