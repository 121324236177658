import { useState, useEffect } from "react";
import parse from 'html-react-parser';
import { Container } from "react-bootstrap";

//importing components
import Navbar from "../../components/common/navbar/Navbar";
import Footer from "../../components/common/footer/Footer";

import { getContentService } from "../../services/content.service";

const AboutUs = () => {
    const [content, setContent] = useState()

    const fetchContent = async () => {
        const result = await getContentService("about_us");
        setContent(result?.data?.configuration?.config_value);
    };

    useEffect(() => {
        fetchContent()
    }, [])

    return (
        <>
            <Navbar />
                <div className='d-flex custom-container  justify-content-center'> 
                    <Container className='sm:main-reward-container mx-4'>
                        <div className="text-sm md:text-base leading-relaxed break-words">
                            {content ? parse(content) : <p>Loading...</p>}
                        </div>
                    </Container>
                </div>
            <Footer />
        </>
    );
}

export default AboutUs;
