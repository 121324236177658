/* eslint-disable no-undef */
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
//importing styles
import "../../assets/scss/pages/onLive/onLive.scss";

//impoting images
import searchIcon from "../../assets/images/Onlive/searchIcon2.webp";
import TodayBanner from '../../assets/images/Banner/bannerToday.webp'

//importing components
import LiveCourse from "../../components/Course/LiveCourse/LiveCourse";
import BannerWithText from "../../components/Banner/BannerWithText";
import Paginate from "../../components/common/paginate/Paginate";
import Layout from "../../components/common/layout/layout";

//importing services
import { getClassService } from "../../services/dashboard.service";
import {
  selectClassList,
  setClassList,
} from "../../redux/classSlice";
import { selectBannerList } from "../../redux/bannerSlice";
import { getLanguageByClass } from "../../services/class.service";
import { selectCategoryList } from "../../redux/categorySlice";
import TodayClasses from "../../components/common/todayClasses/TodayClasses";
import UpComingClasses from "../upcomingClasses/UpcomingClasses";

//Search impliment
import { useSearchParams } from "react-router-dom";


const ExploreAllClasses = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const Classes = useSelector(selectClassList);
  const categories = useSelector(selectCategoryList);
  const [activeTab, setActiveTab] = useState();
  const [totalItem, setTotalItem] = useState();
  const [language, setLanguage] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  let [searchParams] = useSearchParams();


  const onLiveBanner = useSelector(selectBannerList)?.filter(
    // (item) => item?.type?.toLowerCase() === "onlive"
    (item) => item?.type?.toLowerCase() === slug
  );
  const date = moment(new Date()).format("YYYY-MM-DD");
 
  
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const searchQuery = decodeURIComponent(searchParams?.get("search"));
        
        const params = {
          filter: slug?.toLowerCase() === 'all-upcoming-courses' ? '' :slug,
          perPageItem: 12,
          page: 1,
          language: activeTab,
          category: selectedCategory,
          up_coming_date: (slug?.toLowerCase() === 'onstage' || slug?.toLowerCase() === 'onference' || slug?.toLowerCase() === 'special') ? "" : date,
          up_coming_classes: (slug?.toLowerCase() === 'onlive' || slug?.toLowerCase() === 'all-upcoming-courses'),
        };
  
        // Include `searchQuery` only if it is not null
        if (searchQuery !== 'null') {
          params.searchQuery = searchQuery;
        }
        
        const result = await getClassService(params);
        setTotalItem(result?.data?.classes?.total);
  
        // Optionally dispatch
        // dispatch(setClassList(result?.data?.classes?.data));
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchClasses();
    // eslint-disable-next-line
  }, [activeTab, selectedCategory, slug]);
  

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };
  // let timer;
  const handleSearch = async (event) => {
    const { value } = event?.target;
    const result = await getClassService({
      filter: slug?.toLowerCase() === 'all-upcoming-courses' ? '' :slug,
      perPageItem: 12,
      page: 1,
      searchQuery: value,

      up_coming_date: (slug?.toLowerCase() === 'onstage' || slug?.toLowerCase() === 'onference' || slug?.toLowerCase() === 'special' )?"": date,

      up_coming_classes: (slug?.toLowerCase() === 'onlive' ||slug?.toLowerCase() === 'all-upcoming-courses' )
    });
    setTotalItem(result?.data?.classes?.total);
    dispatch(setClassList(result?.data?.classes?.data));
  };

  // eslint-disable-next-line
  const optimisedVersion = useCallback(debounce(handleSearch), []);

  useEffect(() => {
    const fetchLanguageByClass = async () => {
      const result = await getLanguageByClass({
        classSlug: slug?.toLowerCase() === 'all-upcoming-courses' ? '' :slug,
      });
      setLanguage(result?.data?.classesByLanguages);
    };
    fetchLanguageByClass();
    // eslint-disable-next-line
  }, []);

  const totalItems = language?.reduce(
    (accumulator, currentValue) => accumulator + currentValue?.languageCount,
    0
  );


  useEffect(() => {
    if (language?.length === 1 && language[0]?.name === "Bahasa Indonesia") {
      setActiveTab(language[0]?.language_id);
    }
  }, [language]);
  return (
    <>
      <Layout>
        <div className="onLive-main-container custom-container max-w-home">
          <BannerWithText
            title={onLiveBanner ? onLiveBanner[0]?.title : ''}
            description={onLiveBanner ? onLiveBanner[0]?.description : ''}
            image={slug.toLowerCase() === 'all-upcoming-courses' ?TodayBanner :onLiveBanner ? onLiveBanner[0]?.image : ''}
          />

          {slug.toLowerCase() !== 'onthego' && slug.toLowerCase() !== 'all-upcoming-courses' && slug.toLowerCase() !== 'special'  && <LiveCourse />}
          
          {(slug.toLowerCase() !== 'onthego' && slug.toLowerCase() !== 'onlive' && slug.toLowerCase() !== 'all-upcoming-courses' ) && <UpComingClasses />}

          <div className="onLive-inner-container mx-md-5 my-md-5 mx-3 my-3">
            <h2 className="fw-bold fs-4 mb-4">
              {slug.toLowerCase() === 'onlive' ? "Upcoming OnLIVE" :slug.toLowerCase() === 'all-upcoming-courses'? 'Upcoming LIVE Courses':  Classes ? Classes[0]?.class_type?.name : ''}
            </h2>
            <div className="onlive-tab-container d-flex flex-wrap align-items-center gap-2">
              {!(language?.length === 1 && language[0]?.name === "Bahasa Indonesia") && (
                <div
                  onClick={() => setActiveTab(0)}
                  className={`${!activeTab && "active-bg"} d-flex align-items-center gap-2 px-3 py-1 cursor-pointer`}
                >
                  <span className={`${activeTab ? "active-text" : "text-secondary"}`}>
                    All
                  </span>
                </div>
              )}

              {language?.map((item) => (
                <div
                  key={item?.language_id}
                  onClick={() => setActiveTab(item?.language_id)}
                  className={`${activeTab === item?.language_id && "active-bg"} d-flex align-items-center gap-2 px-3 py-1 cursor-pointer`}
                >
                  <span className={`${activeTab === item?.language_id ? "active-text" : "text-secondary"}`}>
                    {item?.name}
                  </span>
                </div>
              ))}
            </div>

            <div className="onlive-filter-container d-flex align-items-center justify-content-between  mb-4 mt-3 ">
              <div className="search-container">
                <img src={searchIcon} alt="icon" />
                <input
                  className="form-control left-search-padding"
                  type="search"
                  placeholder="Search By Title, Keywords"
                  aria-label="Search"
                  onChange={optimisedVersion}
                />
              </div>
              <div className="dropdown-container">
                <select
                  className="dropdown-itemMenu form-control"
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <option>Select category</option>
                  {categories?.map((item) => {
                    return (
                      <option className="cursor-pointer" value={item?.slug}>
                        {item?.name}
                      </option>
                    );
                  })}
                  {/* <option className="dropdown-items">Eng</option>
                  <option className="dropdown-items">Malay</option> */}
                </select>
              </div>
            </div>

            <Paginate totalItem={totalItem} slug={slug}  activeTab={activeTab} selectedCategory={selectedCategory} />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ExploreAllClasses;
