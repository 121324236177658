import ReactGA from "react-ga4";

const google_analytic_key = 'G-NSN07WNBMM'; //for prod

// const google_analytic_key = ''; //for staging


if (google_analytic_key) {
  ReactGA.initialize(google_analytic_key, {
    gtagOptions: { consent_mode: "granted" },
  });
  
} else {
  console.warn("Google Analytics key is missing!");
}

export const trackPageView = (page) => {
  ReactGA.send({ hitType: "pageview", page });
};
