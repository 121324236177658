import moment from "moment";

//import react rating
import { Rating } from "react-simple-star-rating";

//importing images
import ratingIcon from "../../../../assets/images/Rating/ratingIcon.webp";
//importing styles
import "../../../../assets/scss/components/card/CoachCard/CoachCard.scss";
import avatar from "../../../../assets/images/Auth/avatar2.webp";

import Image from "../../image";
import { useState } from "react";

// import cover from "../../../../assets/images/CoachReview/cover.png";


const CoachCard = (props) => {
    const [isExpanded, setIsExpanded] = useState(false);

  // Set a character limit for truncation
  const charLimit = 100;

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };


    return (
        <div>
            <div className="coachCard-main-container cursor-pointer mx-2">
                <div className="card border-0 shadow-sm mb-4">
                    <div className="mx-4">

                        <div className="d-flex justify-content-between align-items-center ">
                            
                            <div className="d-flex gap-2 align-items-center mt-3">
                                <div className="rounded-circle overflow-hidden">
                                    <Image
                                        src={props?.item?.user?.image  || props?.item?.reviewer_image } 
                                        alt="bg" 
                                        // className="coach-bgImg" 
                                        defaultImage={avatar}
                                        style={{ width: "45px", height: "45px" }}

                                    />
                                </div>
                                <div>
                                <div className="d-flex">
                                    <p
                                        className="mb-0 fw-bold text-capitalize overflow-hidden text-truncate"
                                        style={{ maxWidth: "150px" }} // Adjust maxWidth as needed
                                        title={props?.item?.user?.full_name || props?.item?.reviewer_name} // Show full name on hover
                                    >
                                        {props?.item?.user?.full_name || props?.item?.reviewer_name}
                                    </p>

                                </div>
                                
                                </div>
                            </div>
                            
                        </div>
                        <div className="mx-2 my-2 d-flex justify-content-between">
                            <Rating
                                initialValue={props?.item?.rating?.toFixed(2)}
                                value={props?.item?.rating?.toFixed(2)}
                                customIcons={ratingIcon}
                                readonly={true}
                                size={25}
                                allowFraction={true}
                            />
                            <div className="mx-2 my-1  d-flex justify-content-end" >{moment(props?.item?.updated_at).format("D MMM YYYY")}</div>


                        </div>
                       
                        {/* <a className="text-capitalize" href='/'>classes attended: {props?.item?.class?.title}</a> */}
                        <div className="fw-medium py-3">
                        {/* Conditionally render truncated or full text */}
                            {isExpanded || props?.item?.review?.length <= charLimit
                                ? props?.item?.review
                                : `${props?.item?.review?.substring(0, charLimit)}...`}
                            {/* Show "See more" or "See less" button based on state */}
                            {props?.item?.review?.length > charLimit && (
                                <span
                                onClick={handleToggle}
                                className="text-primary cursor-pointer mx-2 "
                                >
                                {isExpanded ? 'See less' : 'See more'}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CoachCard;
