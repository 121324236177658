import React from 'react';
import Tablet from '../../../../assets/images/LearnAndEarn/tablet.png';
import RightArrow from '../../../../assets/images/LearnAndEarn/right-arrow.png'


const RewardCard = () => {
    return (
        <div>
            <div className='bg-white border' style={{ width: "200px" , borderRadius:'10px' }}>
                <img src={Tablet} style={{width:'198px' , height:'160px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}/>
                <div className='my-2 mx-3'>
                    <div className='fw-bold'>Xiaomi Redmi</div>
                    <div style={{fontSize:'12px'}}>
                        Unlock exclusive gadgets as  by by to completing tasks, reaching to to milestones, or participating in special events.
                    </div>
                    <div className='fw-semibold text-primary my-1'  style={{fontSize:'16px'}}>
                        2,500 points
                    </div>
                    <div className='fw-bold text-primary  cursor-pointer' style={{fontSize:'14px'}}>
                        Claim Now 
                        <img src={RightArrow} className='my-2 mx-1'/>
                    </div>
                </div>
                

            </div>
            
        </div>
    );
};

export default RewardCard;