import Account from '../account/Account';

// Image Import
import Smallicon from '../../assets/images/LearnAndEarn/reskillroundimage.webp'
import watch from '../../assets/images/LearnAndEarn/L&A-watch.webp'
import globe from '../../assets/images/LearnAndEarn/L&A-glob.webp'
import book from '../../assets/images/LearnAndEarn/L&A.png-book.webp'
import Certificate from '../../assets/images/LearnAndEarn/L&A.png-Cert.webp'
import arrow from '../../assets/images/LearnAndEarn/left-arrow.webp'

// Import Css
import '../../assets/scss/pages/learnAndEarn/learnAndEarn.scss'

const LearningReward = () => {
    return (
        <Account>
            <div className='tran-container d-flex  justify-content-center'>
                <div className='main-tr-container '>
                    <h4 className='main-title fw-bold py-3'><img src={arrow} alt='arrow' className=' mb-1 mr-arrow-2' />Learning Rewards</h4>
                    <h6>Earn 2.4pts ($2.4) for referring any friends to join in ReSkills Learn & Earn Program.</h6>
                    <small className='text-muted'>(No max rewards cap for each account on referral rewards)</small>
                    <div className='shadow-sm rounded my-4 border border-light  flex-column p-3 bg-card-primary'>

                        <div className='mx-2 d-flex justify-content-between'>
                            <div className='fw-semibold mx-2 text-white'>Learning Points (1 point = $1)</div>
                            <img src={Smallicon} style={{ height: "30px", width: "30px" }} alt="logo" />
                        </div>
                        <div className='fs-4 fw-bold text-white mx-3'>2350</div>
                    </div>

                    <div className='d-flex flex-wrap justify-content-between mb-4'>

                        <div className='shadow-sm rounded my-3 border border-light  py-3 px-4 mx-2'>
                            <img src={watch} alt='watch' style={{ height: "55px", width: "55px" }} />
                            <h6 className='text-primary fw-semibold mx-2'>Minutes Spent</h6>
                            <h3 className='text-primary fw-bold mx-2'>706</h3>
                        </div>
                        <div className='shadow-sm rounded my-3 border border-light  py-3 px-4 mx-2'>
                            <img src={Certificate} alt='watch' style={{ height: "55px", width: "55px" }} />
                            <h6 className='text-primary fw-semibold mx-2'>My Certificate</h6>
                            <h3 className='text-primary fw-bold mx-2'>2</h3>
                        </div><div className='shadow-sm rounded my-3 border border-light  py-3 px-4 mx-2'>
                            <img src={book} alt='watch' style={{ height: "55px", width: "55px" }} />
                            <h6 className='text-primary fw-semibold mx-2'>Class Attended</h6>
                            <h3 className='text-primary fw-bold mx-2'>5</h3>
                        </div><div className='shadow-sm rounded my-3 border border-light  py-3 px-4 mx-2'>
                            <img src={globe} alt='watch' style={{ height: "55px", width: "55px" }} />
                            <h6 className='text-primary fw-semibold mx-2'>OnLIVE Streak</h6>
                            <h3 className='text-primary fw-bold mx-2'>5</h3>
                        </div>

                    </div>



                </div>
            </div>
        </Account>
    );
};

export default LearningReward;