import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

// Importing components
import VerticalCard from "../../common/cards/courseCards/VerticalCard";
import Button from "../../common/button/Button";

// Importing styles
import "../../../assets/scss/components/course/onliveClassListing/onliveClassListing.scss";
import SiteSlider from "../../common/slider/SiteSlider";
import {
    selectClassTypes,
    selectOnLiveClasses,
    setOnLiveClasses,
} from "../../../redux/classSlice";
import { getClassService } from "../../../services/dashboard.service";

const OnLiveClassListing = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    // Ensure selectClassTypes always returns an array
    const classTypes = useSelector(selectClassTypes) || [];

    // Filter class types for "onlive"
    const getSlug = classTypes.filter((item) => 
        item?.name?.toLowerCase() === "onlive"
    );

    const date = moment(new Date()).format("YYYY-MM-DD");

    useEffect(() => {
        const fetchOnliveClasses = async () => {
            setIsLoading(true);
            try {
                if (getSlug.length > 0) {
                    const result = await getClassService({
                        filter: getSlug[0]?.slug,
                        perPageItem: 12,
                        up_coming_date: date,
                        up_coming_classes: getSlug[0]?.slug?.toLowerCase() === 'onlive'
                    });

                    dispatch(setOnLiveClasses(result?.data?.classes?.data || []));
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        };

        if (getSlug.length > 0 && getSlug[0]?.slug) {
            fetchOnliveClasses();
        }
    }, [getSlug.length]); // Depend on getSlug.length instead of getSlug[0]?.slug

    const onLiveClasses = useSelector(selectOnLiveClasses) || [];

    return (
        <>
            {onLiveClasses.length > 0 && (
                <div className="OnliveClassListing-main-container">
                    <h2 className="fw-bold fs-4 mb-1 mx-2">OnLIVE Courses</h2>
                    <div className="d-flex align-items-center justify-content-between flex-wrap mx-2">
                        <p className="OnliveClassListing-main-text">
                            365 days daily LIVE Classes learning with global coaches in real-time
                        </p>
                        {getSlug.length > 0 && (
                            <Link to={`/courses/${getSlug[0]?.slug}`}>
                                <div className="my-2">
                                    <Button name="Explore All Courses" btnType="whiteButton" />
                                </div>
                            </Link>
                        )}
                    </div>
                    <div className="mt-2">
                        <SiteSlider cardType="vertical" noOfItems={onLiveClasses.length}>
                            {onLiveClasses.map((item, index) => (
                                <div className="m-2" key={`onlive-${index}`}>
                                    <VerticalCard cardContent={item} Loading={isLoading} />
                                </div>
                            ))}
                        </SiteSlider>
                    </div>
                </div>
            )}
        </>
    );
};

export default OnLiveClassListing;
