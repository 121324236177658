import Account from '../account/Account';

// Image Import
import Smallicon from '../../assets/images/LearnAndEarn/reskillroundimage.webp'
import share from '../../assets/images/LearnAndEarn/Icon.webp'
import arrow from '../../assets/images/LearnAndEarn/left-arrow.webp'

// Import Css

import '../../assets/scss/pages/learnAndEarn/learnAndEarn.scss'
import Button from '../../components/common/button/Button';

const ReffralReward = () => {
    return (
        <Account>
            <div className='tran-container d-flex  justify-content-center'>
                <div className='main-tr-container '>
                    <h4 className='main-title fw-bold py-3'><img src={arrow} alt='arrow' className=' mb-1 mr-arrow-2' />Referral Rewards</h4>
                    <h6>Earn 2.4pts ($2.4) for referring any friends to join in ReSkills Learn & Earn Program.</h6>
                    <small className='text-muted'>(No max rewards cap for each account on referral rewards)</small>
                    <div className='shadow-sm rounded my-4 border border-light  flex-column p-3 bg-card-primary'>

                        <div className='mx-2 d-flex justify-content-between'>
                            <div className='fw-semibold mx-2 text-white'>Total Earned Referral Reward Points (1 point = $1)</div>
                            <img src={Smallicon} style={{ height: "30px", width: "30px" }} alt='logo' />
                        </div>
                        <div className='fs-4 fw-bold text-white mx-3'>2350</div>
                    </div>


                    <div className='shadow-sm rounded my-3 border border-light  p-3'>
                        <div className='fw-semibold fw-semibold'>Be an inspiration to your friends! Invite them to Learn and Earn with ReSkills now!</div>
                        <div className='my-3 d-flex justify-content-between'>
                            <div className='fw-semibold text-primary'>m.reskills.com/inv-7171</div>
                            <Button
                                name={"Share"}
                                icon={share}
                            />
                        </div>

                    </div>
                    <div className='my-4'>
                        <h5 className='fw-bold'>How it works</h5>
                        <ol className='fw-semibold text-muted'>
                            <li>Share your invitation link with your friends</li>
                            <li>They will download & signup ReSkills</li>
                            <li>Activate their ReSkills Account to ReSkills  Premium</li>
                            <li>They enjoy their learning! You got your rewards.</li>
                        </ol>
                    </div>

                </div>
            </div>
        </Account>
    );
};

export default ReffralReward;