import React from "react";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import ratingIcon from "../../../../assets/images/Rating/ratingIcon.webp";

const CoachCourceHistoryCard = (props) => {
  return (
    <div className="shadow-sm rounded cursor-pointer p-1">
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
        <div className="row g-0 w-100">
          <div className="col-md-5 col-12 p-1 d-flex justify-content-center">
            <img
              src={props?.item?.thumb_image}
              alt="profile"
              className="img-fluid rounded coach-card-img-new"
            />
          </div>
          <div className="col-md-7 col-12">
            <div className="mx-2 text-center text-md-start">
              <Link className="mb-1 d-block fw-bold text-primary" to={`/course/${props?.item?.slug}`}>
                {props?.item?.title}
              </Link>
              <div className="d-flex justify-content-center justify-content-md-start align-items-center">
                <Rating
                  initialValue={props?.item?.average_rating?.toFixed(2)}
                  value={props?.item?.average_rating?.toFixed(2)}
                  customIcons={ratingIcon}
                  readonly={true}
                  size={20}
                  allowFraction={true}
                />
                <div className="p-1 text-secondary">
                  {props?.item?.average_rating?.toFixed(2)}/5
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachCourceHistoryCard;
