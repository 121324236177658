import check from "../../assets/images/Subscription/CheckIcon.webp";
import { IoMdStar } from "react-icons/io";

//importing styles
import "../../assets/scss/components/subscriptionDetail/subscriptionDetail.scss";

import Button from "../../components/common/button/Button";
import Navbar from "../../components/common/navbar/Navbar";
import Footer from "../../components/common/footer/Footer";

import { React, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useState } from "react";
import { selectLearnAndEarnSubscriptionDetail } from "../../redux/learnAndEarnSubscriptionSlice";
import {
  paymentPageService,
  applyScratchCodeService,
} from "../../services/learnAndEarn.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { paymentService } from "../../services/subscription.service";
import icon from "../../assets/images/Breadcrumb.webp"
import slash from "../../assets/images/backslash.webp"
import { Link } from "react-router-dom";
import Cookies from "js-cookie";


const GuestSubCheckOut = () => {
  const [show, setShow] = useState(false);
  const [showSignIn, setShowSingIn] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState("normal");
  const [selected, setSelected] = useState("LEP");

  const { state } = useLocation();

  

  const [isChecked, setIsChecked] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setShowError(false); // Hide error when checkbox is checked
  };

  const subscriptionDetails = useSelector(selectLearnAndEarnSubscriptionDetail);

  /**
   * @function handlePayment
   * @params
   * @description used to handle payment
   */
  const handlePayment = async () => {
    if (!isChecked) {
      setShowError(true);
      // toast.error("You must agree to the Terms & Conditions to proceed");
      return;
    }
    setLoading(true);
    const result = await paymentPageService();
    if (result?.data?.data?.respDesc === "Success") {
      window.location.href = result?.data?.data?.webPaymentUrl;
    } else {
      toast.error(result?.response?.data?.error || "Your payment is failed");
      // navigate('/account/learn-and-earn')
    }
    setLoading(false);
  };

  const handleNormalPayment = async () => {
    if (!isChecked) {
      setShowError(true);
      // toast.error("You must agree to the Terms & Conditions to proceed");
      return;
    }
    setLoading(true);
    const result = await paymentService();
    if (result?.data?.data?.respDesc === "Success") {
      
      window.location.href = result?.data?.data?.webPaymentUrl;
    } else {
      toast.error(result?.response?.data?.error || "Your payment is failed");
    }

    setLoading(false);
  };

  return (
    <div>
      <Navbar />
      <div className="login-out-container-larger-view fw-semibold">
                <div className="d-flex gap-1 mx-md-5 mx-3 mt-3 align-items-center ">
                    <Link to={'/'}>
                        <img src={icon} style={{width:'28px' , height: '28px' }} alt="icon" /> 
                    </Link>
                    <img src={slash} style={{width:'13px' , height: '20px' }}  alt="icon" /><span className="text-primary px-2 py-1 rounded">Subscription</span><img src={slash} style={{width:'13px' , height: '20px' }}  alt="icon" /><span className="text-primary px-2  " style={{background:'#EFF4FF', borderRadius:'10px',paddingTop:'2px',paddingBottom:'2px'}}>Checkout</span>
                </div>
            </div>

      <div className="w-100 d-flex justify-content-center my-5 gap-5">
        <div className="subscriber-container-check  ">
          {state?.subscriptionType === 'LEP' ?  (
            <div
              className={`shadow-sm rounded   subscriber-card   glow-border `}
              
            >
              <h3 className="fw-bold text-end text-primary p-2">$24</h3>
              <div className="p-3 px-5 ">
                <h4 className="fw-bold text-center">2-Year</h4>
                <h5 className="fw-semibold text-center">
                  {" "}
                  Premium Subscription
                </h5>
                <p
                  className="text-center text-white fw-semibold mx-auto "
                  style={{
                    background: "#F79009",
                    width: "120px",
                    borderRadius: "20px",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                  }}
                >
                  Most Popular
                </p>
                <div>
                 
                </div>
                <hr style={{ color: "#98a2b3" }} className="my-3" />
                <div className="d-flex ">
                  <IoMdStar style={{ color: "#FFD700" }} size={24} />
                  <h5 className="fw-bold text-start">EXTRA BENEFITS</h5>
                  <IoMdStar style={{ color: "#FFD700" }} size={24} />
                </div>

                <div className="mt-3">
                  <h6 className="text-primary fw-semibold">
                    Learning Point System
                  </h6>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Accumulate Points</h6>
                  </div>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Reward Selection</h6>
                  </div>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Added Learning Experience</h6>
                  </div>

                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Expansive Network and Community</h6>
                  </div>
                </div>
                <hr style={{ color: "#98a2b3" }} className="my-3" />

                <p className="fw-bold text-start">FEATURES</p>
                <div className="mt-3">
                  <h6 className="text-primary fw-semibold">ReSkills OnLive</h6>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Unlimited Daily LIVE Classes</h6>
                  </div>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Cource Assessment</h6>
                  </div>{" "}
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Accredited Certificates</h6>
                  </div>
                </div>
                <div className="mt-3">
                  <h6 className="text-primary fw-semibold">ReSkills OnTheGo</h6>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Self-Paced Video Learning</h6>
                  </div>
                </div>
                <div className="mt-3">
                  <h6 className="text-primary fw-semibold">ReSkills OnStage</h6>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Industry Leaders' Talks & Sharing</h6>
                  </div>
                </div>
                <div className="mt-3">
                  <h6 className="text-primary fw-semibold">
                    ReSkills OnFerence
                  </h6>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Online Learning Conference</h6>
                  </div>
                </div>
                <div className="mt-3">
                  <h6 className="text-primary fw-semibold">ReSkills On-1</h6>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">
                      Short and Focused Learning Contents
                    </h6>
                  </div>
                </div>
                <div className="mt-3">
                  <h6 className="text-primary fw-semibold">ReSkills Special</h6>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">
                      Tailored Learning Campaigns
                      <br /> and Activities
                    </h6>
                  </div>
                </div>
                <div className="mt-3">
                  <h6 className="text-primary fw-semibold">
                    Learner's Profile
                  </h6>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Learning Dashboard</h6>
                  </div>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Assessment Report</h6>
                  </div>
                </div>
                <div className="mt-3">
                  <h6 className="text-primary fw-semibold">Support</h6>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Full Dedicated Support</h6>
                  </div>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Community of Like-Minded Learners</h6>
                  </div>
                </div>
              </div>
            </div>
          ):
          (
            <div
              className={`shadow-sm rounded   subscriber-card   glow-border `}
              onClick={() => {
                setSelected("normal");
              }}
            >
              <h3 className="fw-bold text-end text-primary p-2">$12</h3>
              <div className="p-3 px-5 ">
                <h4 className="fw-bold text-center">1-Year</h4>
                <h5 className="fw-semibold text-center">
                  {" "}
                  Premium Subscription
                </h5>
                <p
                  className="text-center text-white fw-semibold mx-auto"
                  style={{
                    background: "#5e51a1",
                    width: "100px",
                    borderRadius: "20px",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                  }}
                >
                  Value Pack
                </p>
                
                <hr style={{ color: "#98a2b3" }} className="my-3" />
                <p className="fw-bold text-start">FEATURES</p>

                <div className="mt-3">
                  <h6 className="text-primary fw-semibold">ReSkills OnLive</h6>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Unlimited Daily LIVE Classes</h6>
                  </div>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Cource Assessment</h6>
                  </div>{" "}
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Accredited Certificates</h6>
                  </div>
                </div>
                <div className="mt-3">
                  <h6 className="text-primary fw-semibold">ReSkills OnTheGo</h6>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Self-Paced Video Learning</h6>
                  </div>
                </div>
                <div className="mt-3">
                  <h6 className="text-primary fw-semibold">ReSkills OnStage</h6>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Industry Leaders' Talks & Sharing</h6>
                  </div>
                </div>
                <div className="mt-3">
                  <h6 className="text-primary fw-semibold">
                    ReSkills OnFerence
                  </h6>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Online Learning Conference</h6>
                  </div>
                </div>
                <div className="mt-3">
                  <h6 className="text-primary fw-semibold">ReSkills On-1</h6>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">
                      Short and Focused Learning Contents
                    </h6>
                  </div>
                </div>
                <div className="mt-3">
                  <h6 className="text-primary fw-semibold">ReSkills Special</h6>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">
                      Tailored Learning Campaigns
                      <br /> and Activities
                    </h6>
                  </div>
                </div>
                <div className="mt-3">
                  <h6 className="text-primary fw-semibold">
                    Learner's Profile
                  </h6>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Learning Dashboard</h6>
                  </div>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Assessment Report</h6>
                  </div>
                </div>
                <div className="mt-3">
                  <h6 className="text-primary fw-semibold">Support</h6>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Full Dedicated Support</h6>
                  </div>
                  <div className="d-flex gap-2">
                    <img
                      src={check}
                      className=""
                      alt="check"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "6px",
                      }}
                    />
                    <h6 className="my-2 ">Community of Like-Minded Learners</h6>
                  </div>
                </div>
              </div>
            </div>
          ) 
        }
        </div>
        <div style={{ width: "2px", height: "200vh", backgroundColor: "#F2F4F7" }} />

        <div className="full-width-sub">
          <div className=" bg-muted rounded px-3 py-4 glow-border-white">
            <div className="bg-white p-3 rounded">
                <small className="text-primary fw-semibold">
                {
                state?.subscriptionType === "LEP" ? 
                
                    <div
                    className="text-center text-white fw-semibold  "
                    style={{
                    background: "#F79009",
                    width: "100px",
                    borderRadius: "20px",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                    }}
                >
                    Most Popular
                </div>:
                <div
                className="text-center text-white fw-semibold "
                style={{
                    background: "#5e51a1",
                    width: "90px",
                    borderRadius: "20px",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                }}
                >
                Value Pack
                </div>

                }
                </small>
                <h2 className="fw-bold">
                {state?.subscriptionType === "LEP" ? "$26.00" : "$13.00"}
                </h2>
                <small className="text-primary fw-semibold mx-1">
                    {state?.subscriptionType === "LEP" ? "2-Year" : "1-Year"}
                </small>
                
            </div>
            <div className="d-flex justify-content-between fs-6 my-2 fw-semibold">
                <div>Subtotal</div>
                <div>{state?.subscriptionType === "LEP" ? "$24.00" : "$12.00"}</div>
                </div>
            <div className="d-flex justify-content-between fs-6 my-2 fw-semibold">
              <div>Processing Charge</div>
              <div>
                $
                {state?.subscriptionType === "LEP"
                  ? subscriptionDetails?.processing_amount
                  : "1"}
                .00
              </div>
            </div>

            <hr style={{ color: "#98a2b3" }} className="my-3" />

            <div className="d-flex justify-content-between  my-2 fw-bold">
              <div>Total</div>
              <div className="text-bold ">
                {state?.subscriptionType === "LEP" ? "$26.00" : "$13.00"}
              </div>
            </div>
            <div className="d-flex gap-2 my-2">
              <input type="checkbox" required onChange={handleCheckboxChange} />
              <small className={showError ? "text-danger" : ""}>
                I agree to the
                <small
                  className="text-primary mx-1 fw-semibold cursor-pointer"
                  onClick={() => {
                    navigate(
                        state?.subscriptionType === "LEP"
                        ? "/account/learn-and-earn/term-and-condition"
                        : "/subscription/payment-term-and-condition"
                    );
                  }}
                >
                  Terms & Conditions
                </small>
              </small>
            </div>
           

            <button
              className="form-control btn btn-primary w-100"
              onClick={
                state?.subscriptionType === "LEP" ? handlePayment : handleNormalPayment
              }
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Proceed to Pay"}
            </button>
            <div className=" text-center" style={{paddingTop:'2px'}}>
            <small className="fw-bold">
              Have a gift card?
              <small
                className="text-primary mx-1 fw-bold cursor-pointer"
                onClick={() => {
                  navigate("/redeem");
                }}
              >
                Redeem here
              </small>
            </small>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GuestSubCheckOut;
