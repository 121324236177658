import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import FeaturedCoachCard from "../cards/FeaturedCoachCard/FeaturedCoachCard";
import { getReviewDetail } from "../../../services/coach.service";
import "../../../assets/scss/components/paginate/paginate.scss";
import NoData from "../noData/NoData";
import CoachCard from "../cards/CoachCard/CoachCard";
import { Spinner } from "react-bootstrap";

function Items({ currentItems }) {
  if (currentItems?.length === 0) {
    return <NoData page="no-coach-found" />;
  }

  

  return (
    <>
      {currentItems?.map((item, index) => (
        <div key={index}>
          <CoachCard item={item} />
        </div>
      ))}
    </>
  );
}

const ReviewPaginate = ({ id }) => {
  const [coaches, setCoaches] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const itemsPerPage = 9;
  const [isLoading , setLoading] = useState(false)

  useEffect(() => {
   
    const fetchCoaches = async () => {
      setLoading(true)
      try {
        const result = await getReviewDetail({
          per_page: itemsPerPage,
          page: currentPage + 1,
          id:id
        });

        const fetchedCoaches = result?.data?.reviews || [];
        setTotalItem(result?.data?.total_reviews || 0);
        setCoaches(fetchedCoaches);
        setLoading(false)
      } catch (error) {
        console.error("Error fetching coaches:", error);
        setLoading(false)
      }
    };
   

    fetchCoaches();
  }, [currentPage, id]);

  const pageCount = Math.ceil(totalItem / itemsPerPage);

  const handlePageClick = async (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
    setCurrentPage(event.selected);
  };

  return (
    <>
      {isLoading ?
        <div className='d-flex justify-content-center align-items-center my-5 w-100'>
            <Spinner className='mx-auto' animation="border" />
        </div>
      :
      <div className="d-flex flex-wrap gap-4 justify-content-start">
        <Items currentItems={coaches} />
      </div>}
      {pageCount > 1 && (
        <div className="d-flex justify-content-center">
          <ReactPaginate
            breakLabel="..."
            nextLabel={currentPage === pageCount - 1 ? null : "Next"}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={currentPage === 0 ? null : "Previous"}
            renderOnZeroPageCount={null}
            className="paginationComponent"
            containerClassName="pagination"
            previousClassName={currentPage === 0 ? "previous hide-arrow" : "previous"}
            nextClassName={currentPage === pageCount - 1 ? "hide-next-arrow" : "next"}
          />
        </div>
      )}
    </>
  );
};

export default ReviewPaginate;
