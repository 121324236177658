import { useState , useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
//importing components
import Input from "../../../components/common/input/Input";
import Button from "../../../components/common/button/Button";

//importing images
import siteLogo from "../../../assets/images/newSiteLogo.webp";
import Cookies from "js-cookie";




//importing styles
import "../../../assets/scss/pages/auth/signUp/signUp.scss";
import Dropdown from "../../../components/common/dropdown/Dropdown";
import PasswordStrength from "../../../components/common/passwordStrength/PasswordStrength";
import InputWithPhoneCode from "../../../components/common/input/InputWithPhoneCode";

//import services
import { signUpUser } from "../../../services/auth.service";
import { setUserSession, updateUserSession } from "../../../utils/Auth.Service";
import { useDispatch, useSelector } from "react-redux";
import { selectCountryList, setToken } from "../../../redux/userSlice";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";


function GuestSignUpModal({show , onHide ,setActiveModal ,onSignIn , selected , setShowOtp , setUserEmail ,setAccessToken , setNewAccessToken}) {
     const navigate = useNavigate();
        const dispatch = useDispatch();
        const countryList = useSelector(selectCountryList);
        const [isLoading, setIsLoading] = useState(false);
        const [selectedCountry, setSelectedCountry] = useState();
        const [selectedCountryId , setSelectedCountryId]= useState()
        const verifyLater = sessionStorage.getItem('verify_later') || false

        
        // Initialize useSearchParams
        const [searchParams] = useSearchParams();
        // Get the value of referral_code
    

    
    
        const translatedTexts = ''
        //Translation
    
    
    
        const [formData, setFormData] = useState({
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            password: "",
            password_confirmation: "",
            news_letter_subscription: 0,
            privacy_policy: false,
            captcha_response: "",
            
        });
        const [errorMessage, setErrorMessage] = useState({});
    
        /**
            * @function signUpUser
            * @param { first_name, last_name, email, phone_number, country_code, country_id, password, password_confirmation,captcha_response }
            * @description This function is used to register user
        */
        const handleSignUpUser = async () => {
            setIsLoading(true);
            if (formData) {
                const result = await signUpUser({
                    first_name: formData?.first_name,
                    last_name: formData?.last_name,
                    email: formData?.email,
                    phone_number: formData?.phone_number,
                    country_code: countryList?.find(item => parseInt(item?.id) === parseInt(selectedCountry))?.isd_code,
                    country_id: countryList?.find(item => parseInt(item?.id) === parseInt(selectedCountry))?.id,
                    password: formData?.password,
                    password_confirmation: formData?.password_confirmation,
                    news_letter_subscription: formData?.news_letter_subscription,
                    privacy_policy: formData?.privacy_policy,
                    captcha_response: formData?.captcha_response,
                    
                });
                if (result?.data?.status === "success") {
                    const token = result?.data?.user?.verification_token;
                    const user = result?.data?.user?.id;
                    const tokens = {
                        tokenAccess: token,
                    };

                    // if(!verifyLater){
                    //     Cookies.set("tokenAccess", result?.data?.access_token);
                    // }

                    setAccessToken(result?.data?.user?.verification_token)
                    setNewAccessToken(result?.data?.access_token)
                    // dispatch(setToken(tokens?.tokenAccess))
                    // setUserSession(tokens, user);
                    updateUserSession(user)
                    // navigate("/otp-verification", {
                    //     state: {
                    //         type: "register",
                    //         email: formData?.email,
                    //         first_name: formData?.first_name
                    //     },
                    // });
                    setUserEmail(formData?.email)
                    setShowOtp()

                }
                if (result?.response?.data?.errors) {
                    setErrorMessage(result?.response?.data?.errors);
                    toast.error(result?.response?.data?.message)
                }
            }
            setIsLoading(false);
        };
    
        /**
            * @function handleOnChange
            * @param { non }
            * @description This function is used to set the input values
        */
        const handleOnChange = (e) => {
            const { name, value } = e.target;
            setFormData({
                ...formData,
                [name]: value,
            });
        };
    
        /**
            * @function handleCheckBox
            * @param { non }
            * @description This function is used to set the checkbox values
        */
        const handleCheckBox = (e) => {
            const { name, checked } = e.target;
            if (name === "news_letter_subscription") {
                setFormData({
                    ...formData,
                    [name]: checked === true ? "1" : "0",
                });
            } else {
                setFormData({
                    ...formData,
                    [name]: checked,
                });
            }
        };
    
        /**
            * @function onChange
            * @param {  recaptcha token }
            * @description This function is used to set generated recaptcha token
        */
        function onChange(value) {
            setFormData({
                ...formData,
                captcha_response: value,
            });
        }
    



    return (

        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>

            <Modal.Header className="px-4 pb-0 border-0" closeButton>
                <Modal.Title>
                <Link to="/">
                            <img src={siteLogo} alt="siteLogo" className="mb-4" />
                        </Link>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="custom-container max-w-home">
            
            <div className="signUp-main-container d-flex">
                <div className="">
                
                    <div className="text-start">
                        

                        <h2 className="fw-bold fs-3">{translatedTexts?.sign_up||'Sign Up'}</h2>
                    </div>
                    <div>
                        <div className="mb-3">
                            <label className={`${errorMessage?.first_name && "errorColor"}   fs-6`}>
                               {translatedTexts?.firstName ||'First Name'} <span className="text-danger">*</span>
                            </label>
                            <Input
                                errorClass={`${errorMessage?.first_name && "error-border"} `}
                                name="first_name"
                                placeholder={translatedTexts?.first_placeholder ||"Enter first name"}
                                onChange={(e) => handleOnChange(e)}
                            />
                            {errorMessage?.first_name && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    {translatedTexts?.firstname_eroor || errorMessage?.first_name[0]}
                                </p>
                            )}
                            <p className="m-0 text-secondary fs-6">
                                {translatedTexts?.firstWarning  || 'The registered name will be printed on the certificate and cannot be changed. Please make sure the spelling is accurate.'}
                            </p>
                        </div>
                        <div className="mb-3">
                            <label className={`${errorMessage?.last_name && "errorColor"}   fs-6`}>
                                {translatedTexts?.lastName|| 'Last Name'} <span className="text-danger">*</span>
                            </label>
                            <Input
                                errorClass={`${errorMessage?.last_name && "error-border"} `}
                                name="last_name"
                                placeholder={translatedTexts?.secound_placeholder ||"Enter last name"}
                                onChange={(e) => handleOnChange(e)}
                            />
                            {errorMessage?.last_name && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    { translatedTexts?.lastname_error|| errorMessage?.last_name[0]}
                                </p>
                            )}
                            <p className="m-0 text-secondary fs-6">
                                {translatedTexts?.firstWarning  || 'The registered name will be printed on the certificate and cannot be changed. Please make sure the spelling is accurate.'}
                            </p>
                        </div>
                        <div className="mb-3">
                            <label className={`${errorMessage?.email && "errorColor"}  fs-6`}>
                                {translatedTexts?.email || 'Email'} <span className="text-danger">*</span>
                            </label>
                            <Input
                                errorClass={`${errorMessage?.email && "error-border"} `}
                                name="email"
                                placeholder={translatedTexts?.email_placeholder ||"Enter your email"}
                                onChange={(e) => handleOnChange(e)}
                            />
                            {errorMessage?.email && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    { errorMessage?.email[0] === 'The email field is required.' ? translatedTexts?.mail_error : errorMessage?.email[0] ==='The email has already been taken.' ? translatedTexts?.mail_taken : errorMessage?.email[0] }
                                </p>
                            )}
                        </div>
                        <div className="mb-3">
                            <label className={`${errorMessage?.country_code && "errorColor"} fs-6`}>
                                {translatedTexts?.country ||'Country'} <span className="text-danger">*</span>
                            </label>
                            <Dropdown
                                options={countryList}
                                defaultOption={translatedTexts?.country_placholder ||"Select the country"}
                                setSelectedCountry={setSelectedCountry}
                                setSelectedCountryId={setSelectedCountryId }
                            />

                            {errorMessage?.country_code && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    {translatedTexts?.country_error || 'The Country field is required'}
                                </p>
                            )}
                        </div>

                        <div className="mb-3">
                            <label className={`${errorMessage?.phone_number && "errorColor"} fs-6`}>
                                {translatedTexts?.phone_number || 'Phone Number'} <span className="text-danger">*</span>
                            </label>
                            <InputWithPhoneCode
                                name="phone_number"
                                errorClass={`${errorMessage?.phone_number && "error-border"} `}
                                placeholder ={translatedTexts?.phone_placeholder}
                                phoneCode={
                                    selectedCountry && countryList?.find(item => parseInt(item?.id) === parseInt(selectedCountry))?.isd_code
                                }
                                onChange={(e) => handleOnChange(e)}
                            />
                            {errorMessage?.phone_number && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    {translatedTexts?.phone_error || errorMessage?.phone_number[0]}
                                </p>
                            )}
                        </div>
                        <div className="mb-3">
                            <label className={`${errorMessage?.password && "errorColor"} fs-6`}>
                                {translatedTexts?.create_password || 'Create Password'} <span className="text-danger">*</span>
                            </label>
                            <Input
                                errorClass={`${errorMessage?.password && "error-border"} `}
                                name="password"
                                placeholder={ translatedTexts?.password_placeholder || "Enter your password"}
                                type="password"
                                onChange={(e) => handleOnChange(e)}
                            />
                            {errorMessage?.password && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    {errorMessage?.password[0] === 'The password field is required.' ? translatedTexts?.pass_error:errorMessage?.password[0] === 'The password field confirmation does not match.' ? translatedTexts?.pass_match : errorMessage?.password[0]}
                                </p>
                            )}
                            {formData?.password && (
                                <PasswordStrength 
                                    password={formData?.password}
                                    passStr1 ={translatedTexts?.passStr1}
                                    passStr2 ={translatedTexts?.passStr2}
                                    passStr3 ={translatedTexts?.passStr3}
                                    passStr4 ={translatedTexts?.passStr4}
                                    passStr5 = {translatedTexts?.passStr5}
                                />
                            )}
                        </div>
                        <div className="mb-3">
                            <label className="fs-6">{translatedTexts?.confirm_password|| 'Confirm Password'} <span className="text-danger">*</span></label>
                            <Input
                                name="password_confirmation"
                                placeholder={ translatedTexts?.password_placeholder || "Enter your password"}
                                type="password"
                                onChange={(e) => handleOnChange(e)}
                            />
                            {errorMessage?.password_confirmation && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    {errorMessage?.password_confirmation[0]}
                                    
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mb-3 mt-3">
                        <div className="form-check d-flex gap-2">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                name="news_letter_subscription"
                                onChange={handleCheckBox}
                            />
                            <p className="m-0 fs-6 fw-normal">{translatedTexts?.keep_me || 'Keep me informed about new contents and exciting events.'}</p>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="form-check d-flex gap-2">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                name="privacy_policy"
                                onChange={handleCheckBox}
                            />
                            <p className="m-0 fs-6 fw-normal">
                                {translatedTexts?.agree  || 'I agree with the'} <Link className="text-decoration-none" to="/terms">{ translatedTexts?.tnc || 'Terms and Conditions'}</Link> { translatedTexts?.and ||'and'} <Link className="text-decoration-none" to="/privacy"> { translatedTexts?.pnp || 'Privacy Policy'}</Link>
                            </p>
                        </div>
                        {errorMessage?.privacy_policy && (
                            <p style={{ fontSize: "13px", color: "#f04438" }}>
                                { translatedTexts?.error_pp || 'Please check the terms and privacy policy box.'}
                            </p>
                        )}
                    </div>
                    <div className="my-3">
                        <ReCAPTCHA
                            sitekey="6LcxA54pAAAAAG756fqqQSy621XWQ3wk5CTgDvYE"
                            onChange={onChange}
                        />
                    </div>
                    <Button
                        name={`${isLoading ? ( translatedTexts?.loading|| "Loading...") : ( translatedTexts?.register_now ||"Register Now") }`}
                        onClick={handleSignUpUser}
                        disable={(formData?.captcha_response ? false : true) || isLoading || !/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/.test(formData.password)}
                    />
                    <div className="mt-3 text-center">
                        <p className="text-secondary fs-6">
                            {translatedTexts?.account_have  || 'Already have an account?'}{" "}
                            <span
                                className="text-primary cursor-pointer"
                                onClick={() => navigate('/login')}
                            >
                                { translatedTexts?.sign_in || 'Sign in'}
                            </span>
                        </p>
                    </div>
                </div>
                
            </div>
            
        </div>
            </Modal.Body>
           
            
        </Modal>
    );
}

export default GuestSignUpModal;