import React, { useState } from 'react';
// img
import Up from '../../../../assets/images/LearnAndEarn/up.png';
import Down from '../../../../assets/images/LearnAndEarn/down.png';

const DrawerCards = ({name, children }) => {
    const [arrow, setArrow] = useState(true);

    const toggleArrow = () => {
        setArrow(prevState => !prevState);
    };

    return (
        <div>
            <div className='card w-100 my-4 p-2 px-4'>
                <div className='d-flex justify-content-between'>
                    <p className='fw-bold fs-5 my-2'>{name}</p>
                    <div className='my-2 cursor-pointer' onClick={toggleArrow}>
                        {arrow ? (
                            <img src={Up} alt='up' style={{ height: '10px' }} />
                        ) : (
                            <img src={Down} alt='down' style={{ height: '10px' }} />
                        )}
                    </div>
                </div>

                <div
                    className={`w-100 transition-height ${arrow ? 'expanded' : 'collapsed'}`}
                >
                    
                    {arrow && <div>{children}</div>}
                </div>
            </div>
        </div>
    );
};

export default DrawerCards;
