import React, { useState } from 'react';
import icon from "../../assets/images/Auth/OtpIcon.webp";
import backIcon from "../../assets/images/Auth/BackIcon.webp";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Form, Modal } from 'react-bootstrap';
import Button from '../common/button/Button';
import { useNavigate } from 'react-router-dom';
import { SendOtpUpdateEmail } from '../../services/auth.service';

const UpdateEmailModal = ({ show, onHide, verification_token ,setCameFromUpdateEmail , setRemainResendAttempt , setSeconds ,oldEmail}) => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur"
    });
    const [loading, setLoading] = useState(false);

    const handleUpdateEmail = async (formdata) => {
        setLoading(true);
        try {
            const result = await SendOtpUpdateEmail({
                email: formdata.email,
                verification_token: verification_token,
            });
            console.log(result?.data , 'mypack')
            if (result?.data?.status === "success") {
                toast.success(result.data.message);
                setCameFromUpdateEmail(true)
                setRemainResendAttempt(0)
                setSeconds(0)
                navigate('/otp-verification', {
                    state: {
                        from: 'verify-otp',
                        email: formdata.email,
                        verification_token: verification_token,
                        old_email : oldEmail
                        // from: 'sign-in-update'
                    }
                });
                onHide();
            } else if (result?.response?.data?.status === "error") {
                toast.error(  "It looks like the email address provided is already associated to another user’s account. Please proceed to login or use another email."||result.response.data.message);

            } else if (result?.data?.message) {
                toast.error(result.data.message || "Something went wrong");
            } else if (result?.response?.data?.message) {
                toast.error(result.response.data.message || "Something went wrong");
            }
        } catch (error) {
            console.error("An unexpected error occurred:", error);
            toast.error("An unexpected error occurred.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='w-100'>
            <Modal show={show} onHide={onHide}>
                <Modal.Header className="px-4 pb-0 border-0" closeButton></Modal.Header>
                <div className="p-4 container">
                    <div className="d-flex justify-content-center align-items-center flex-column">
                        <img src={icon} alt="icon" className="mb-4" style={{ height: '80px', width: '80px' }} />
                        <h2 className="fw-bold fs-2 mb-2 text-center">Update Email Address</h2>
                        <p className="text-secondary text-center">Please provide your new email address to <br /> receive a verification code. </p>
                    </div>
                    <Form className='w-100' onSubmit={handleSubmit(handleUpdateEmail)}>
                        <div className='px-5'>
                            <div>
                                <span className='fw-semibold'>Email</span>
                                <input
                                    type="text"
                                    placeholder="Enter your email"
                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                    {...register("email", {
                                        required: "Email is required",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Enter a valid email"
                                        }
                                    })}
                                />
                                {errors.email && <p style={{ fontSize: "13px", color: "#f04438" }}>{errors.email.message}</p>}
                            </div>
                            <div className="mt-4">
                                <Button
                                    name={loading ? 'Loading...' : 'Get Verification Code'}
                                    disable={loading}                                />
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>
        </div>
    );
};

export default UpdateEmailModal;
