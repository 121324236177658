import { useSelector } from "react-redux";
//importing components
import Navbar from "../../components/common/navbar/Navbar";
import Footer from "../../components/common/footer/Footer";
import CategoryCard from "../../components/common/cards/categoryCard/CategoryCard";
import { selectCategoryList } from "../../redux/categorySlice";

//importing styles
import "../../assets/scss/components/AllCategory/AllCategory.scss";

import Home from '../../assets/images/Sidebar/Home.webp'
import { useEffect } from "react";

const AllCategory = () => {
    const categories = useSelector(selectCategoryList);

     useEffect(() => {
        document.title = "Reskills | Category"
        }, []);

    return (
        <div  >
            <Navbar />
            <div className="custom-container max-w-home">
                <div className="AllCategory-main-container">
                    <div className='my-4'>
                        <div className='d-flex mb-3'>
                            <img src={Home} alt='' style={{ height: "15px", width: "15px" }} className='m-2' />
                            <div className='py-1 px-2 font-mute'>/</div>
                            <div className='m-1 my-account px-2 rounded fw-semibold '><small >Category</small></div>
                        </div>
                        <div className="d-flex align-items-center  mb-md-0 me-md-auto  text-decoration-none">
                            <h4 className="fs-5 d-none d-sm-inline fw-bold px-2 min-width-0">Category</h4>
                        </div>
                    </div>

                    <div className=" w-100">
                        <div className="AllCategory-card-container row w-100">
                            {categories?.map((item, index) => {
                                return (
                                    <div className="col-4" key={index}>
                                        <CategoryCard
                                            cardDetail={item}
                                            cardType="cardWithCheckHover"
                                        />
                                    </div>
                                );
                            })}


                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AllCategory;
