import { useState, useEffect } from "react";
import { BsCheck2 } from "react-icons/bs";

const PasswordStrength = ({ password ,passStr1 ,passStr2,passStr3,passStr4,passStr5 }) => {
   const [passwordOneLowerUpperCase, setPasswordOneLowerUpperCase] = useState(false);
   const [passwordOneNumber, setPasswordOneNumber] = useState(false);
   const [passwordOneSpecialCharacter, setPasswordOneSpecialCharacter] = useState(false);
   const [passwordLength, setPasswordLength] = useState(false);

   const passwordNumberRegex = /^(?=.*\d)/;
   const passwordSpecialRegex = /^(?=.*\W)/;
   const passwordLengthRegex = /^.{8,}$/;
   const passwordLowerUpperRegex = /^(?=.*[a-z])(?=.*[A-Z])/;

   useEffect(() => {
      setPasswordOneLowerUpperCase(passwordLowerUpperRegex.test(password));
      setPasswordOneSpecialCharacter(passwordSpecialRegex.test(password));
      setPasswordOneNumber(passwordNumberRegex.test(password));
      setPasswordLength(passwordLengthRegex.test(password));
      // eslint-disable-next-line
   }, [password]);

   return (
      <div>
         <p className="m-0 mt-2 fs-6 text-secondary">{passStr1||'Your password must contain:'}</p>
         <div className="d-flex align-items-center gap-2">
            <BsCheck2 className={`${passwordOneLowerUpperCase ? "text-success" : "text-danger"}`} size={16} />
            <p className={`m-0 fs-6 ${passwordOneLowerUpperCase ? "text-success" : "text-danger"}`}>
               {passStr2 ||'A lowercase and uppercase letter(a-z;A-Z)'}
            </p>
         </div>
         <div className="d-flex align-items-center gap-2">
            <BsCheck2 className={`${passwordOneNumber ? "text-success" : "text-danger"}`} size={16} />
            <p className={`m-0 fs-6 ${passwordOneNumber ? "text-success" : "text-danger"}`}>
               {passStr3||'a number'}
            </p>
         </div>
         <div className="d-flex align-items-center gap-2">
            <BsCheck2 className={`${passwordOneSpecialCharacter ? "text-success" : "text-danger"}`} size={16} />
            <p className={`m-0 fs-6 ${passwordOneSpecialCharacter ? "text-success" : "text-danger"}`}>
              {passStr4||' a special character($&@!#%^*)'}
            </p>
         </div>
         <div className="d-flex align-items-center gap-2">
            <BsCheck2 className={`${passwordLength ? "text-success" : "text-danger"}`} size={16} />
            <p className={`m-0 fs-6 ${passwordLength ? "text-success" : "text-danger"}`}>
               {passStr5||'at least 8 characters'}
            </p>
         </div>
      </div>
   );
};

export default PasswordStrength;
