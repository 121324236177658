import axios from "axios";

/**
    * @function getCountryList
    * @param { Non }
    * @description This function is used to get the country list
*/
export const getCountryList = async () => {
    try {
        const result = await axios.get(`user/get-countries`);
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function signUpUser
    * @param { first_name, last_name, email, phone_number, country_code, country_id, password, password_confirmation, news_letter_subscription, privacy_policy, captcha_response }
    * @description This function is used to signup API functioning the user
*/
export const signUpUser = async ({ first_name, last_name, email, phone_number, country_code, country_id, password, password_confirmation, news_letter_subscription, privacy_policy, captcha_response ,referral_code , partner_code }) => {
    try {
        const result = await axios.post(`user/register-with-recaptcha`, {
            first_name,
            last_name,
            email,
            phone_number,
            country_code,
            country_id,
            password,
            password_confirmation,
            news_letter_subscription,
            privacy_policy,
            captcha_response,
            referral_code,
            partner_code
        });
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function loginUser
    * @param { email, password, captcha_response }
    * @description This function is used to signin API functioning the user
*/
export const loginUser = async ({ email, password, captcha_response }) => {
    try {
        const result = await axios.post(`user/login-with-recaptcha`, {
            email,
            password,
            captcha_response
        });
        return result;
    } catch (error) {
        return error;
    }
};

/**
 * @function OTPValidation
 * @param {otpType, otp}
 * @description This function is used to validate OTP
 */
export const OTPValidation = async ({ email, otp_type, otp }) => {
    try {
        const result = await axios.post(`user/verify-otp`, {
            email,
            otp_type,
            otp
        });
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function forgetPasswordService
    * @param { email }
    * @description This function is used to send OTP to user-phone for reset forget-password
*/
export const forgetPassword = async ({ email }) => {
    try {
        const result = await axios.post(`user/forget-password`, {
            email
        });
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function resetPasswordService
    * @param { email, otp, password, password_confirmation }
    * @description This function is used to reset password
*/
export const resetPassword = async ({ email, otp, password, password_confirmation }) => {
    try {
        const result = await axios.post(`user/reset-password`, {
            email,
            otp,
            password,
            password_confirmation
        });
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function resendOtpService
    * @param { email, otp }
    * @description This function is used to reset password
*/
export const resendOtp = async ({ email, otp }) => {
    try {
        const result = await axios.post(`user/resend-otp`, {
            email,
            otp
             
        });
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function resendUpdateOtp
    * @param { old_email, new_email }
    * @description This function is used to resend otp for Updated email
*/
export const resendUpdateOtp = async ({ old_email, new_email}) => {
    try {
        const result = await axios.post(`user/update-email-otp`, {
            old_email,
            new_email
        });
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function logoutService
    * @param
    * @description This function is used to logout API functioning the user
*/
export const logout = async () => {
    try {
        const result = await axios.post(`user/logout`, {});
        return result;
    } catch (error) {
        return error;
    }
}


/**
    * @function SendOtpUpdateEmail
    * @param { email, verification_token }
    * @description This function is used to update-email
*/
export const SendOtpUpdateEmail = async ({ email, verification_token }) => {
    try {
        const result = await axios.post(`user/send-otp-update-email`, {
            email,
            verification_token
        });
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function VerifyOtpUpdateEmail
    * @param { email, verification_token ,otp }
    * @description This function is used to update-email
*/
export const VerifyOtpUpdateEmail = async ({ otp ,email, verification_token }) => {
    try {
        const result = await axios.post(`user/verify-otp-update-email`, {
            otp,
            email,
            verification_token
        });
        return result;
    } catch (error) {
        return error;
    }
};