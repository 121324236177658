//importing styles
import "../../../assets/scss/components/input/inputWithPhoneCode.scss";

const InputWithPhoneCode = ({ name, phoneCode, onChange, errorClass ,errorInClass , placeholder }) => {
  return (
    <>
      {phoneCode ? (
        <div className="inputWithPhoneCode-container">
          <p
            className={`${errorClass 
              } input-PhoneCode text-secondary`}
          >
            {phoneCode ? phoneCode : "00"}
          </p>
          <input
            name={name}
            type="text"
            className={`${errorInClass } form-control`}
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            placeholder={placeholder || "Enter phone number"}
            onChange={onChange}
            inputMode="numeric"
          />
        </div>
      ) : (
        <input
          name={name}
          type="text"
          className={`${errorInClass } form-control`}
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          placeholder={placeholder || "Enter phone number"}
          onChange={onChange}
          inputMode="numeric"
        />
      )}
    </>
  );
};

export default InputWithPhoneCode;
