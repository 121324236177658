import { Modal } from "react-bootstrap";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";

//importing components
import Button from "../common/button/Button";

//importing styles
import "../../assets/scss/components/modal/recordingAlertModal.scss";

import { classDeRegistrationService } from "../../services/class.service";
import { toast } from "react-toastify";
import { useState } from "react";




const SecRegisterModal = ({ show, setShow ,secRegClass,setUpdate ,classType}) => {
    const handleClose = () => setShow(false);
    const [loading , setLoading] =useState(false)

    const handleClassDeRegistration = async (classId) => {
        setLoading(true);
        const result = await classDeRegistrationService({
            classId: secRegClass?.conflicting_class_id,
        });
        if (result?.data?.message) {
            toast.success(result?.data?.message);
            setShow(false)
            setUpdate('modal')
        }        
        else {
            toast.error(result?.response?.data?.error );
        }
        setLoading(false);
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <div className="py-4 px-3">
                
                <div className="my-4">
                    <p className="fs-5 text-center ">You have already registered for another course at this time. Please click ’Deregister’ to remove yourself from the <label className=" text-primary fw-bold  mb-0">{classType} </label> course titled<label className="truncate-2-lines flex-grow-1 text-primary fw-bold me-3 mb-0">{secRegClass?.conflicting_class_name} </label> </p>
                    
                </div>
                <div className="d-flex align-items-center justify-content-end gap-2 mt-5">
          <div>
            <Button
              btnType="whiteButton"
              name="Cancel"
              onClick={handleClose}
            />
          </div>
          <div
            className="btn-container"
            style={{ width: "100px" }}

          >
            <Button name="Deregister" onClick={handleClassDeRegistration} 
              disable={loading}
            />
          </div>
        </div>
                    
                
            </div>
        </Modal>
    );
};

export default SecRegisterModal;
