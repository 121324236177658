import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import "../../../assets/scss/components/paginate/paginate.scss";
import NoData from "../noData/NoData";
import { setMissionList, selectMissionList } from "../../../redux/missionSlice";
import { getMissions } from "../../../services/learnAndEarn.service";
import Check from "../../../assets/images/LearnAndEarn/check.webp";


function Items({ currentItems, isLoading, fetchMissionBeginButton, fetchMissionShow ,currentPage}) {
  if (!Array.isArray(currentItems) || currentItems.length === 0) {
    return <NoData />;
  }

  return (
    <div className="card-container">
      {currentItems?.map((item, index) => (
        <div key={index} className="shadow-sm rounded my-3 border border-light p-3">
          {item?.sub_missions?.length > 0 && (
            <>
              <div className="my-3">
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">{item?.title}</div>
                </div>
                <small className="text-muted">{item?.short_description}</small>
              </div>

              {!(item?.sub_missions?.[0]?.button_label === 'Begin') &&
              <div className="container d-flex my-3 justify-content-between align-items-center">
                <div className="progress w-100">
                  <div
                    className="progress-bar bg-progress"
                    role="progressbar"
                    style={{ width: `${(item?.progress_data?.completed_sub_missions/item?.progress_data?.total_sub_missions)*100}%` }}
                    aria-valuenow={25}
                    aria-valuemin="0"
                    aria-valuemax={100}
                  ></div>
                </div>
                <span className="fw-semibold mx-2">
                  {item?.progress_data?.completed_sub_missions}/{item?.progress_data?.total_sub_missions}
                </span>
              </div>}
            </>
          )}

          {item?.sub_missions?.length === 0 && (
            <div className="bg-muted rounded my-2 d-flex justify-content-between">
              <div className="text-muted fw-semibold mx-1 my-2">{item?.title}</div>

            {!(item?.status === "completed") ?
              (<button
                className="primary-color-btn btn btn-primary btn-sm p-2 shadow-sm fw-bold rounded px-3"
                onClick={() =>
                  item?.button_label === "Begin" ? fetchMissionBeginButton(item?.id ,currentPage+1 ) : fetchMissionShow(item?.id)
                }
                disabled={isLoading}
              >
                {item?.button_label || "Next"}
              </button>
              ):(
                <div className="fw-semibold fw-bold  my-2 d-flex" style={{ color: "#20BB73" }}>
                  <img
                    src={Check}
                    className="mx-1 my-1"
                    alt="ticket"
                    style={{ height: "20px", marginBottom: "2px" }}
                  />
                  <div>Completed</div>
                </div>
              )}
            </div>
          )}

          {item?.sub_missions?.length > 0 &&
            item?.sub_missions?.map((subItem, subIndex) => (
              <div className="bg-muted rounded d-flex justify-content-between border-bottom" key={subIndex}>
                <div className="text-muted fw-semibold mx-1 my-2">{subItem?.title}</div>
                <div>
                  {!(subItem?.status === "completed") ? (
                    <button
                      className="primary-color-btn btn btn-primary btn-sm p-2 shadow-sm fw-bold rounded px-3"
                      onClick={() =>
                        subItem?.button_label === "Begin" ? fetchMissionBeginButton(subItem?.id ,currentPage+1) : fetchMissionShow(subItem?.id)
                      }
                      disabled={isLoading}
                    >
                      {subItem?.button_label || "Next"}
                    </button>
                  ) : (
                    <div className="fw-semibold fw-bold  my-2 d-flex" style={{ color: "#20BB73" }}>
                      <img
                        src={Check}
                        className="mx-1 my-1"
                        alt="ticket"
                        style={{ height: "20px", marginBottom: "2px" }}
                      />
                      <div>Completed</div>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      ))}
    </div>
  );
}

const MissionPaginate = ({ isLoading, fetchMissionBeginButton, fetchMissionShow }) => {
  const dispatch = useDispatch();
  const missions = useSelector(selectMissionList);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const itemsPerPage = 8;

  const fetchMission = useCallback(async () => {
    const result = await getMissions({
      per_page: itemsPerPage,
      page: currentPage + 1,
    });

    setTotalItem(result?.data?.total || 0);

    dispatch(setMissionList(result?.data?.data || []));
  }, [currentPage, dispatch]);

  useEffect(() => {
    fetchMission();
  }, [fetchMission]);

  const pageCount = Math.ceil(totalItem / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
    setCurrentPage(event.selected);
  };

  return (
    <>
      <div>
        <Items
          currentItems={missions}
          isLoading={isLoading}
          fetchMissionShow={fetchMissionShow}
          fetchMissionBeginButton={fetchMissionBeginButton}
          currentPage={currentPage}
        />
      </div>
      { (
        <div className="d-flex justify-content-center">
          <ReactPaginate
            breakLabel="..."
            nextLabel={currentPage === pageCount - 1 ? null : "Next"}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={currentPage === 0 ? null : "Previous"}
            renderOnZeroPageCount={null}
            className="paginationComponent"
            containerClassName="pagination"
            previousClassName={currentPage === 0 ? "previous hide-arrow" : "previous"}
            nextClassName={currentPage === pageCount - 1 ? "hide-next-arrow" : "next"}
          />
        </div>
      )}
    </>
  );
};

export default MissionPaginate;
