import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { Form } from 'react-bootstrap';
// import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
// import { FaCircleCheck } from "react-icons/fa6";
// import classNames from "classnames";

// Custom Component
import Layout from "../../components/common/layout/layout";
import Button from "../../components/common/button/Button";

// Images
import check from "../../assets/images/Subscription/CheckIcon.webp";

//Css
import '../../assets/scss/pages/subscription/subscription.scss'
// API Service
// import { subscriptionDetailsService, applyPromoCodeService, paymentService } from "../../services/subscription.service";
import { subscriptionDetailsService, paymentService } from "../../services/subscription.service";

const Payment = () => {
    const [subscriptionDetails, setSubscriptionDetails] = useState();
    // const [promoCode, setPromoCode] = useState();
    // const [enterPromoCode, setEnterPromoCode] = useState(false);

    // const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm({
    //     mode: "onBlur"
    // });
    const [isChecked, setIsChecked] = useState(false);
    const [showError, setShowError] = useState(false);
    const navigate = useNavigate()

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        setShowError(false); // Hide error when checkbox is checked
    };

    /**
        * @function fetchSubscriptionDetails
        * @params
        * @description used to fetch subscription details
    */
    const fetchSubscriptionDetails = async () => {
        const result = await subscriptionDetailsService();
        setSubscriptionDetails(result?.data)
    };

    useEffect(() => {
        fetchSubscriptionDetails();
        // eslint-disable-next-line
    }, []);

    /**
        * @function handlePayment
        * @params
        * @description used to handle payment
    */
    const handlePayment = async () => {
        if (!isChecked) {
            setShowError(true);
            // toast.error("You must agree to the Terms & Conditions to proceed");
            return;
        }
        const result = await paymentService();
        if (result?.data?.data?.respDesc === "Success") {
            window.location.href = result?.data?.data?.webPaymentUrl
        } else {
            toast.error(result?.response?.data?.error || "Your payment is failed")
        }
    }

    /**
        * @function onSubmit
        * @param (formdata)
        * @description This function is used to apply promo code
    */
    // const onSubmit = async (formdata) => {
    //     const result = await applyPromoCodeService({
    //         promo_code: formdata.promo_code
    //     });
    //     if (result?.data?.message && result?.data?.status === "error") {
    //         toast.error(result?.data?.message || "Something went wrong")
    //         setPromoCode()
    //     } else if (result?.response?.data?.message && result?.response?.data?.status === "error") {
    //         toast.error(result?.response?.data?.message || "Something went wrong")
    //         setPromoCode()
    //     } else if (result?.data?.status === "success") {
    //         toast.success(result?.data?.message || "Promo code applied successfully.")
    //         setPromoCode({
    //             discount_amount: result?.data?.discount_amount,
    //             promo_code: formdata.promo_code
    //         })
    //     }
    // }

    return <Layout currentPage="Subscription">
        <div className="mx-md-5 mx-3 d-md-flex gap-3 align-items-start my-5">
            <div className="w-md-75 w-100">
                <div className="card  mx-auto ">
                    <div className="card-header bg-white d-md-flex align-items-start justify-content-between p-4">
                        <div className="d-flex gap-2 align-items-center">
                            <p className="m-0 fw-bold">Premium plan</p>{" "}
                            <span className="fs-6 px-2 fw-semibold text-primary border rounded-pill sub-pill-border ">
                                Popular
                            </span>{" "}
                        </div>
                        <div className="d-flex gap-2 align-items-end">
                            <p className="m-0 fs-1 fw-bold">$12</p>
                            <span className="mb-2">Per year</span>
                        </div>
                    </div>
                    <div>
                        <h5 className="sub-m-y fw-bold mx-4">FEATURES</h5>
                        <div className="mx-4 sub-container">
                            <div className="">
                                <div>
                                    <p className="text-size fw-bold my-2">Reskill OnLive</p>
                                    <div className="d-flex">
                                        <img src={check} className="mx-2" alt="check" />
                                        <p className="fs-5 mt-sub-2">Unlimited Daily LIVE Classes</p>
                                    </div>
                                    <div className="d-flex">
                                        <img src={check} className="mx-2" alt="check" />
                                        <p className="fs-5 mt-sub-2">Assessment On All Classes</p>
                                    </div><div className="d-flex">
                                        <img src={check} className="mx-2" alt="check" />
                                        <p className="fs-5 mt-sub-2">Pay Per Cert</p>
                                    </div>

                                </div>
                                <div>
                                    <p className="text-size fw-bold my-2">ReSkills OnTheGo</p>
                                    <div className="d-flex">
                                        <img src={check} className="mx-2" alt="check" />
                                        <p className="fs-5 mt-sub-2">Self Pace Videos Learning</p>
                                    </div>
                                </div>
                                <div>
                                    <p className="text-size fw-bold my-2">Reskill Library</p>
                                    <div className="d-flex">
                                        <img src={check} className="mx-2" alt="check" />
                                        <p className="fs-5 mt-sub-2">E-Book</p>
                                    </div>
                                    <div className="d-flex">
                                        <img src={check} className="mx-2" alt="check" />
                                        <p className="fs-5 mt-sub-2">E-Magazine</p>
                                    </div><div className="d-flex">
                                        <img src={check} className="mx-2" alt="check" />
                                        <p className="fs-5 mt-sub-2">E-Note</p>
                                    </div>

                                </div>
                            </div>
                            <div className="ml-sub">
                                <div>
                                    <p className="text-size fw-bold my-2">Special Learning</p>
                                    <div className="d-flex">
                                        <img src={check} className="mx-2" alt="check" />
                                        <p className="fs-5 mt-sub-2">1 minute Learning</p>
                                    </div>
                                    <div className="d-flex">
                                        <img src={check} className="mx-2" alt="check" />
                                        <p className="fs-5 mt-sub-2">Podcast Learning</p>
                                    </div>
                                </div>
                                <div>
                                    <p className="text-size fw-bold my-2">Learning Profile</p>
                                    <div className="d-flex">
                                        <img src={check} className="mx-2" alt="check" />
                                        <p className="fs-5 mt-sub-2">Learners Profile</p>
                                    </div>
                                    <div className="d-flex">
                                        <img src={check} className="mx-2" alt="check" />
                                        <p className="fs-5 mt-sub-2">Assessment Report</p>
                                    </div>
                                    <div className="d-flex">
                                        <img src={check} className="mx-2" alt="check" />
                                        <p className="fs-5 mt-sub-2">Learning Reward</p>
                                    </div>
                                </div>
                                <div>
                                    <p className="text-size fw-bold my-2">Support</p>
                                    <div className="d-flex">
                                        <img src={check} className="mx-2" alt="check" />
                                        <p className="fs-5 mt-sub-2">Dedicated Support</p>
                                    </div>
                                </div>
                                <div>
                                    <p className="text-size fw-bold my-2">Others</p>
                                    <div className="d-flex">
                                        <img src={check} className="mx-2" alt="check" />
                                        <p className="fs-5 mt-sub-2">All Freemium Benefits</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {subscriptionDetails &&
                <div className="w-md-25 w-100 p-4 rounded" style={{ backgroundColor: "#eef2f6" }}>
                    <p className="fs-6 text-primary">Price Premium plan</p>
                    <div>
                        <p className="fs-1 fw-bolder">${parseFloat(subscriptionDetails?.subscription_amount)?.toFixed(2)}</p>
                        <p className="fs-6">Yearly pro plan</p>
                    </div>
                    <div>
                        <div className="d-flex align-items-center justify-content-between">
                            <p>SubTotal</p>
                            <p>${parseFloat(subscriptionDetails?.subscription_amount)?.toFixed(2)}</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <p>Processing Charges</p>
                            <p>${subscriptionDetails?.processing_fee}.00</p>
                        </div>
                        {/* <div className="d-flex align-items-center justify-content-between">
                            <p>Promo Code</p>
                            <p role='button' className={classNames({
                                "text-primary": !enterPromoCode,
                                "text-danger": enterPromoCode,
                                "d-none": promoCode
                            })} onClick={() => setEnterPromoCode(!enterPromoCode)}>{enterPromoCode ? "Cancel" : "Apply"}</p>
                        </div>
                        {promoCode &&
                            <div className="d-flex flex-column align-items-start">
                                <span className="fw-bold">‘{promoCode?.promo_code}’ applied</span>
                                <div className="d-flex align-items-center">
                                    <div className="me-2">
                                        <FaCircleCheck className="text-primary" />
                                    </div>
                                    <span className="text-primary">${parseFloat(promoCode?.discount_amount)?.toFixed(2)}</span>
                                </div>
                            </div>
                        } */}
                        {/* {enterPromoCode && !promoCode &&
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <div className="d-flex gap-2 w-100">
                                    <div className="w-75">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter code here"
                                            {...register('promo_code', {
                                                required: "Promo code is required"
                                            })}
                                        />
                                    </div>
                                    <div className="w-25">
                                        <Button
                                            type="submit"
                                            name="Apply"
                                            disable={!isDirty || !isValid}
                                        />
                                    </div>
                                </div>
                                {errors.promo_code && <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    {errors.promo_code.message}
                                </p>}
                            </Form>
                        } */}
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-2">
                        <p className="fw-bold">Total</p>
                        {/* {promoCode ?
                            <p className="fw-bold">${parseFloat(parseFloat(subscriptionDetails?.subscription_amount) + parseFloat(1) - parseFloat(promoCode?.discount_amount))?.toFixed(2)}</p>
                            :
                            <p className="fw-bold">${parseFloat(parseFloat(subscriptionDetails?.subscription_amount) + parseFloat(1))?.toFixed(2)}</p>
                        } */}
                        <p className="fw-bold">${parseFloat(parseFloat(subscriptionDetails?.subscription_amount) + parseFloat(1))?.toFixed(2)}</p>
                    </div>
                    <div className="d-flex gap-2 my-2">
                        <input 
                            type="checkbox"
                            required
                            onChange={handleCheckboxChange}
                        />
                        <small className={showError ? "text-danger" :''}>
                            I agree to the
                            <Link className="text-dark text-decoration-none" to={'/subscription/payment-term-and-condition'}>
                            <small className="text-primary mx-1 fw-semibold cursor-pointer">Terms & Conditions</small>
                            </Link>
                        </small>
                    </div>
                    {/* {showError && (
                        <small className="text-danger">
                            Please agree to the Terms & Conditions to proceed.
                        </small>
                    )} */}
                    <div onClick={handlePayment}>
                        <Button name="Proceed to pay" />
                    </div>
                    <div className="my-2 fw-semibold d-flex">
                        <p>Have a gift card? </p>
                        <p className="text-primary mx-1 cursor-pointer" onClick={() => navigate('/redeem')}>Redeem here</p>
                    </div>

                </div>
            }
        </div>
    </Layout>
}

export default Payment;
