import { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

//importing components
import Button from "../../../components/common/button/Button";

//importing images
import siteLogo from "../../../assets/images/newSiteLogo.webp";
import eyeoff from "../../../assets/images/Auth/eyeoff.webp";
import eyeon from "../../../assets/images/Auth/eyeon.webp";

//importing styles
import "../../../assets/scss/components/input/input.scss";
import "../../../assets/scss/components/signIn/signIn.scss";

import { loginUser } from "../../../services/auth.service";

import { setUserSession } from "../../../utils/Auth.Service";

import { setToken, setUserDetail } from "../../../redux/userSlice";



function GuestSignInModal({show , onHide , subscriptionType ,onSignUp , selected ,setAccessToken ,onShowOtp , setShowSummaryModal}) {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm({
        mode: "onBlur"
    });

    const [showPassword, setShowPassword] = useState(false);
    const [recaptchaResponse, setRecaptchaResponse] = useState();
    const [captchaPass, setCaptchaPass] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const recaptchaRef = useRef(null);

    /**
        * @function onSubmit
        * @param (formdata)
        * @description This function is used to signin API functioning the user
    */
    const onSubmit = async (formdata) => {
        setIsLoading(true);
        const result = await loginUser({
            email: formdata.email,
            password: formdata.password,
            captcha_response: recaptchaResponse,
        });
        if (result?.data?.message && result?.data?.status === "error") {
            toast.error(result?.data?.message || "Something went wrong")
            recaptchaRef.current.reset(); // Reset reCAPTCHA
        } else if (result?.response?.data?.message && result?.response?.data?.status === "error") {
            toast.error(result?.response?.data?.message || "Something went wrong")
            if (result?.response?.data?.message === "Account not verified yet.") {
                // navigate("/otp-verification", {
                //     state: {
                //         type: "register",
                //         email: formdata?.email,
                //         password: formdata?.password,
                //         verificationToken: result?.response?.data?.user?.verification_token,
                //         from: 'sign-in-update'
                //     },
                // });

                setAccessToken(result?.response?.data?.user?.verification_token)
                onShowOtp()
            } else {
                recaptchaRef.current.reset(); // Reset reCAPTCHA
            }
        }
        if (result?.response?.data?.errors) {
            setErrorMessage(result?.response?.data?.errors);
        }


        if (result?.data?.status === "success") {
            const tokens = {
                tokenAccess: result?.data?.access_token,
            };
            const user = result?.data?.user;
            if (user.hasOwnProperty('old_data')) {
                delete user.old_data;
            }
            dispatch(setUserDetail(user));
            dispatch(setToken(tokens?.tokenAccess))
            setUserSession(tokens, user);
            // navigate("/guest-subscription-checkout",{
            //     state :{
            //         subscriptionType:selected
            //     }
            // })

            setShowSummaryModal(true)
            onHide()
            sessionStorage.removeItem('verify_later');
        }
        setIsLoading(false);
    };

    /**
        * @function onCaptchaChange
        * @params value
        * @description set the captcha value
    */
    const onCaptchaChange = (value) => {
        setRecaptchaResponse(value)
        if (value) {
            setCaptchaPass(true)
        } else {
            setCaptchaPass(false)
        }
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header className="px-4 pb-0 border-0" closeButton>
                <Modal.Title>
                <Link to="/">
                            <img src={siteLogo}  alt="siteLogo" className="mb-4" />
                        </Link>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="w-100 d-flex justify-content-between ">
                <div className="w-100  mx-3 my-auto">
                    <div className="text-start">
                       
                        <h2 className="fw-bold fs-3">Sign In</h2>
                        <p className="mb-4 text-secondary">
                            Please enter your login details.
                        </p>
                    </div>
                    <Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <div className="mb-3">
                                <label className={`fs-6`}>
                                    Username/Email <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter your email"
                                    className={`form-control`}
                                    {...register("email", {
                                        required: "Email is required",
                                        // pattern: {
                                        //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        //     message: "Enter a valid email"
                                        // }
                                    })}
                                />
                                {errorMessage?.email && (
                                    <p style={{ fontSize: "13px", color: "#f04438" }}>
                                        {errorMessage?.email[0]}
                                    </p>
                                )}
                                {errors.email && <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    {errors.email.message}
                                </p>}

                            </div>
                            <div className="input-container mb-3">
                                <label className={`fs-6`}>
                                    Password <span className="text-danger">*</span>
                                </label>
                                <div className="input-password-group">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        className={`form-control`}
                                        aria-label="Default"
                                        aria-describedby="inputGroup-sizing-default"
                                        placeholder="Enter your password"
                                        {...register('password', {
                                            required: "Password is required"
                                        })}
                                    />
                                    <div className="input-password-icon-container">
                                        {!showPassword ? (
                                            <img
                                                src={eyeoff}
                                                style={{width:'20px'}}
                                                alt="eyeoffIcon"
                                                onClick={() => setShowPassword(!showPassword)}
                                            />
                                        ) : (
                                            <img
                                                src={eyeon}
                                                style={{width:'20px'}}
                                                alt="eyeonIcon"
                                                onClick={() => setShowPassword(!showPassword)}
                                            />
                                        )}
                                    </div>
                                </div>
                                {errorMessage?.password && (
                                    <p style={{ fontSize: "13px", color: "#f04438" }}>
                                        {errorMessage?.password[0]}
                                    </p>
                                )}
                                {errors.password && <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    {errors.password.message}
                                </p>}
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-start mb-3">
                            <div className="form-check ps-0 d-flex gap-2">
                                {/* <Form.Check
                                    type="checkbox"
                                    id={`remember`}
                                    label={<p className="m-0 fs-6 fw-normal">Remember for 30 days</p>}
                                /> */}
                            </div>
                            <Link className="text-decoration-none" to={"/forget-password"}>
                                <p className="m-0 fs-6">Forgot Password</p>
                            </Link>
                        </div>
                        <div className="my-3">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LcxA54pAAAAAG756fqqQSy621XWQ3wk5CTgDvYE"
                                onChange={onCaptchaChange}
                            />
                            {errorMessage?.captcha_response && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>Please verify</p>
                            )}
                        </div>
                        <div>
                            <Button
                                name={`${isLoading ? "Loading..." : "Sign in"}`}
                                type={'submit'}
                                disable={!isDirty || !isValid || !captchaPass || isLoading}
                            />
                        </div>
                    </Form>
                    <Link
                        className="text-center text-decoration-none"
                        onClick={onSignUp}
                    >
                        <p className="text-secondary mt-3 fs-6">
                            Don't have an account? <span className="text-primary">Sign up</span>
                        </p>
                    </Link>
                </div>
                </div>
            </Modal.Body>
        
            
        </Modal>
    );
}

export default GuestSignInModal;