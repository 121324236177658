import React from 'react';
//img
import Banner from '../../assets/images/LearnAndEarn/reward-frame.png'

//img

import Account from '../account/Account';
import DrawerCards from '../../components/common/cards/DrawerCards/DrawerCards';
import RewardCard from '../../components/common/cards/RewardCard/RewardCard';
import MerchandiseCard from '../../components/common/cards/RewardCard/MerchandiseCard';

const RewardItems = () => {
    return (
        <div className=''>
            <Account>

            <div className="tran-container d-flex justify-content-center" >
                <div className="main-tr-container">
                    <h4 className="fw-bold">Claim Rewards</h4>
                    <div className='shadow-sm rounded border border-light w-100 main-banner'>
                        <img src={Banner} className='img-fluid' style={{ maxHeight: '280px' }} alt="reward-item" />
                    </div>
                        <DrawerCards name ='Cash Reward'>
                            <div className='d-flex flex-wrap gap-2 justify-content-between'>
                                <RewardCard/>
                                <RewardCard/>
                                <RewardCard/>
                                <RewardCard/>
                            </div>
                        </DrawerCards>
                        <DrawerCards name ='Gadget Reward'>
                            <div className='d-flex flex-wrap gap-2 justify-content-between'>
                                <RewardCard/>
                                <RewardCard/>
                                <RewardCard/>
                                <RewardCard/>
                            </div>
                        </DrawerCards> 
                        <DrawerCards name ='Fashion Reward'>
                            <div className='d-flex flex-wrap gap-2 justify-content-between'>
                                <RewardCard/>
                                <RewardCard/>
                                <RewardCard/>
                                <RewardCard/>
                            </div>
                        </DrawerCards> 
                        <DrawerCards name ='Vouchers Reward'>
                            <div className='d-flex flex-wrap gap-2 justify-content-between'>
                                <RewardCard/>
                                <RewardCard/>
                                <RewardCard/>
                                <RewardCard/>
                            </div>
                        </DrawerCards> 
                        <DrawerCards name ='Merchandise Reward'>
                            <div className='d-flex flex-wrap gap-2 justify-content-between'>
                                <MerchandiseCard/>
                                <MerchandiseCard/>
                                <MerchandiseCard/>
                                <MerchandiseCard/>
                            </div>
                        </DrawerCards>
                </div>
            
            </div>
            
            </Account>

        </div>
        

    );
};

export default RewardItems;