import React, { useState } from "react";
import moment from "moment";
import "../../../../assets/scss/pages/TransactionHistory/TransactionHistory.scss";
import Export from "../../../../assets/images/download.png";
import { transactionReceiptService } from "../../../../services/transaction.service";
import { toast } from "react-toastify";

const TransactionHistoryCard = ({ item, index }) => {

  const [loading , setLoading] = useState(false)
  if (!item) return null; // Return null if item is not provided
  

  
  


  const downloadFile = (url) => {
    
    fetch(url).then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document?.createElement('a');
        alink.href = fileURL;
        alink.download = url?.split("/")[url?.split("/")?.length - 1];
        alink.click();
      });
      });
      setLoading(false)
      toast.success("Download Receipt");
  }

  const downloadInvoiceAsJPG = async () => {
    setLoading(true);
    try {
      // Fetch the PDF URL
      const pdfUrl = await transactionReceiptService({ invoiceNo: item?.invoice_no });
      
      // Validate the response 
      if (!pdfUrl || !pdfUrl?.pdf_url) {
        throw new Error('PDF URL not found');
      }
      
      // Proceed with download
      const fileName = item?.invoice_no; // File name for download
      downloadFile(pdfUrl?.pdf_url, fileName);
    } catch (error) {
      console.error('Error while downloading invoice:', error);
      toast.error('Not able to Download the Receipt');
    } finally {
      setLoading(false);
    }
  };
  


  return (
    <div className="my-3" key={`transaction-${index}`}>
      <div className="row d-flex shadow-sm rounded p-3 border border-light content-between w-100">
        <div className="tran-container col-7 d-flex justify-content-between">
          <div className="invoice-div pt-2">
            <small className="text-muted">Invoice</small>
            <div className="fw-semibold text-primary">{item?.invoice_no}</div>
          </div>
          <div className="fw-semibold pt-2 pt-1 mt-2-th small-display-none-tra">
            ${item?.amount}
          </div>
          <div className="pt-2 pt-1 mt-2-th col-4 date-width">
            {moment(item?.payment_date)?.format("DD MMMM YYYY")}
          </div>
          {item?.payment_status === "success" &&
            <div className=" d-flex pt-2">
            <button
              className="btn  btn-sm py-1 px-2 rounded fw-semibold wht-btn large-display-none-tra text-white"
              onClick={() => downloadInvoiceAsJPG()}
              disabled={loading}
              

            >
              {loading ? 'Loading...':'Receipt'}
              {!loading &&
              <img
                src={Export}
                alt="Export Receipt"
                className="mx-2"
                style={{ width: "13px", height: "13px", marginBottom: "3px" }}
              />}
            </button>
          </div>}
        </div>
        <div className="tran-container pt-2 mt-2-th col-4 large-end justify-content-between">
          <div
            className="Sucess-btn px-2 text-white fw-semibold text-capitalize"
            style={{
              background: item?.payment_status === "success" ? "#129155" : "#D92D20",
            }}
          >
            {item?.payment_status}
          </div>
          <div className="fw-semibold pt-2 pt-1 mt-2-th mx-2 large-display-none-tra">
            ${item?.amount}
          </div>

          {item?.payment_status === "success" &&
            <div className="d-flex pt-2 ">
            <button
              className="btn btn-sm py-1 px-2 rounded fw-semibold wht-btn small-display-none-tra text-white"
              onClick={() => downloadInvoiceAsJPG()}
              disabled={loading}
            >
             {loading ? 'Loading...':'Receipt'}
              {!loading &&
              <img
                src={Export}
                alt="Export Receipt"
                className="mx-1"
                style={{ width: "13px", height: "13px", marginBottom: "3px" }}
              />}
            </button>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default TransactionHistoryCard;
